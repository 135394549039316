import { message } from 'antd';
import { Fragment } from 'react';

import { OrganizationDetails } from 'src/components/Account';
import Loader from 'src/components/Loader/Loader';
import { useUpdateCompanyDetailsMutation, useCompanyQuery, useProfileQuery, ICompanyQuery } from 'src/graphql/schema';
import { DestructuredUser } from 'src/components/Account/TeamMembers/TeamMembersTable';

import type { IOrganizationForm } from 'src/components/Account';

import TeamMembersContainer from './TeamMembersContainer';

const AccountContainer: React.FunctionComponent = () => {
  const { data, loading } = useCompanyQuery();
  const [updateCompany] = useUpdateCompanyDetailsMutation();
  const { data: profileData } = useProfileQuery();

  const updateDetails = async (values: IOrganizationForm) => {
    try {
      const { data } = await updateCompany({
        variables: {
          company: {
            companyName: values.company,
            street: values.street,
            streetNumber: values.number,
            city: values.city,
            postalCode: values.zip,
            region: values.state,
            country: values.country,
            companyWebsite: values.website,
            companyRegistration: values.registration,
          },
        },
      });
      if (data?.updateCompanyDetails?.success) {
        message.success('Details updated!');
      } else {
        message.error('Something went wrong!');
      }
    } catch (e) {
      message.error('Something went wrong');
    }
  };

  if (loading) return <Loader />;

  const company: ICompanyQuery['company'] | undefined = data?.company;
  const users: DestructuredUser[] =
    company?.users.map((user) => {
      return {
        name: user.name,
        role: user.role,
        email: user.email,
        phone: user.phone,
        id: user.id,
      } as DestructuredUser;
    }) || [];

  if (profileData)
    return (
      <Fragment>
        <OrganizationDetails
          company={{
            companyName: company?.companyName || '',
            street: company?.street || '',
            streetNumber: company?.streetNumber || '',
            city: company?.city || '',
            postalCode: company?.postalCode || '',
            region: company?.region || '',
            country: company?.country || '',
            companyWebsite: company?.companyWebsite || '',
            companyRegistration: company?.companyRegistration || '',
          }}
          profile={profileData.profile}
          updateDetails={updateDetails}
        />

        <div className="user-settings__container no-height">
          <TeamMembersContainer profile={profileData.profile} users={users} />
        </div>
      </Fragment>
    );

  return null;
};

export default AccountContainer;
