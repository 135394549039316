import { Fragment, useState } from 'react';
import { Button, message } from 'antd';

import { ModalProceed } from 'src/components/Misc';
import { useStartBackupMutation, BackupAssetStatusEnum } from 'src/graphql/schema';
import { useBackup } from 'src/context/backup';

const StartBackupContainer = () => {
  const [isProceedModalOpen, setIsProceedModalOpen] = useState(false);

  const { backup } = useBackup();
  const [startBackup, { loading }] = useStartBackupMutation();

  const onStartBackup = async () => {
    try {
      const { data } = await startBackup({
        variables: { id: backup?.id || '' },
      });

      if (data?.startBackup?.success) {
        message.success('Backup started successfully');
        setIsProceedModalOpen(false);
      } else {
        throw new Error(data?.startBackup?.errors?.[0]);
      }
    } catch (error: unknown) {
      if (error instanceof Error) message.error(error.message || 'Something went wrong');
      throw error;
    }
  };

  const enableStartBackup = backup?.backupAssets.some(({ status }) => status === BackupAssetStatusEnum.Pending);

  return (
    <Fragment>
      <Button type="primary" disabled={!enableStartBackup} onClick={() => setIsProceedModalOpen(true)}>
        Start Backup
      </Button>

      <ModalProceed
        title="Initiate backup process"
        text="Ready to continue with the selected configuration settings?</br>Click on Start Backup to continue."
        isOpen={isProceedModalOpen}
        onCancel={() => setIsProceedModalOpen(false)}
        onProceed={onStartBackup}
        isLoading={loading}
        proceedText="Start Backup"
      />
    </Fragment>
  );
};

export default StartBackupContainer;
