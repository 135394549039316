import { Fragment, useState } from 'react';
import { Button, message } from 'antd';

import { ModalProceed } from 'src/components/Misc';
import { useEscrow } from 'src/context/escrow';
import { ClientError, getApiError } from 'src/utils/errors';
import { useRejectEscrowTerminationMutation } from 'src/graphql/schema';

const RejectTerminationActionContainer = () => {
  const [isProceedModalOpen, setIsProceedModalOpen] = useState(false);

  const { escrow, refetch } = useEscrow();

  const [rejectEscrowTermination, { loading }] = useRejectEscrowTerminationMutation();

  const onRejectTermination = async () => {
    try {
      const { data } = await rejectEscrowTermination({
        variables: { escrowTerminationId: escrow?.escrowTermination?.id || '' },
      });
      if (data?.rejectEscrowTermination?.success) {
        await refetch();
        setIsProceedModalOpen(false);
      } else {
        throw new ClientError(data?.rejectEscrowTermination?.errors?.[0]);
      }
    } catch (error: unknown) {
      if (error instanceof Error) getApiError(error, message.error);
    }
  };

  return (
    <Fragment>
      <Button type="default" onClick={() => setIsProceedModalOpen(true)}>
        Reject Termination
      </Button>

      <ModalProceed
        title="Are you sure you want to reject the escrow termination request?"
        text="The counterparty will be notified and the escrow will return to its previous status."
        isOpen={isProceedModalOpen}
        proceedText="Reject Termination"
        onCancel={() => setIsProceedModalOpen(false)}
        onProceed={onRejectTermination}
        isLoading={loading}
        proceedButtonProps={{ ghost: true, type: 'primary' }}
      />
    </Fragment>
  );
};

export default RejectTerminationActionContainer;
