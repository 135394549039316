import { JourneyModalLayout } from 'src/components/Journey/ModalLayout';
import { useBackup } from 'src/context/backup';

import BackupModalHeaderContainer from './BackupModalHeaderContainer';
import BackupStatusIndicatorContainer from './BackupStatusIndicatorContainer';
import BackupMenuContainer from './BackupMenuContainer';
import BackupAlertBannerContainer from './BackupAlertBannerContainer';
import BackupModalFooterContainer from './BackupModalFooterContainer';

const BackupModalLayoutContainer = ({ children }: React.PropsWithChildren) => {
  const { error } = useBackup();

  return (
    <JourneyModalLayout
      header={<BackupModalHeaderContainer />}
      error={error && { goTo: '/backups', ...error }}
      statusIndicator={<BackupStatusIndicatorContainer />}
      menu={<BackupMenuContainer />}
    >
      <BackupAlertBannerContainer />
      {children}
      <BackupModalFooterContainer />
    </JourneyModalLayout>
  );
};

export default BackupModalLayoutContainer;
