import { cloneDeep } from '@apollo/client/utilities';

import { BackupsDocument, BackupDocument } from 'src/graphql/schema';

import { apolloClient as client, apolloCache as cache } from '../apollo-client';

import type { PartialDeep } from 'type-fest';
import type {
  IBackupQuery,
  IBackupsQuery,
  IBackupAsset,
  IBackup,
  IBackupsQueryVariables,
  IBackupMember,
} from 'src/graphql/schema';

export const updateBackupAssetsCache = (id: string, data: PartialDeep<IBackupAsset>) => {
  const cacheData = cloneDeep<IBackupQuery | null>(
    client.readQuery({
      query: BackupDocument,
      variables: {
        backupId: id,
      },
    }),
  );

  if (!cacheData || !data) return;

  const assets = cloneDeep(cacheData.backup.backupAssets);
  const assetIdx = assets.findIndex((asset) => asset.id === data.id);

  if (assetIdx === -1) assets.push(data as IBackupAsset);
  else {
    assets[assetIdx] = {
      ...assets[assetIdx],
      ...data,
    };
  }

  client.writeQuery({
    query: BackupDocument,
    variables: {
      backupId: id,
    },
    data: {
      me: {
        ...cacheData.me,
      },
      backup: {
        ...cacheData.backup,
        backupAssets: [...assets],
      },
    },
  });
};

export const removeBackupAssetCache = (backupId: string, assetId: string) => {
  const cacheData = cloneDeep<IBackupQuery | null>(
    client.readQuery({
      query: BackupDocument,
      variables: {
        backupId,
      },
    }),
  );

  if (!cacheData) return;

  const backupAsset = cacheData.backup.backupAssets.find((asset) => asset.id === assetId);

  if (!backupAsset) return;

  cache.modify({
    id: cache.identify(backupAsset),
    fields(_fieldValue, { DELETE }) {
      return DELETE;
    },
  });
};

export const updateBackupsCache = (variables: IBackupsQueryVariables, data: PartialDeep<IBackup>) => {
  const cacheData = cloneDeep(
    client.readQuery({
      query: BackupsDocument,
      variables,
    }),
  );

  if (!cacheData) return;

  const backupNodes = cloneDeep<IBackupsQuery['backups']['nodes']>(cacheData.backups.nodes) ?? [];
  const backupNodeIdx = backupNodes.findIndex((node) => node.id === data.id);

  if (backupNodeIdx === -1) return;

  backupNodes[backupNodeIdx] = data as IBackup;

  client.writeQuery({
    query: BackupsDocument,
    variables,
    data: {
      backups: {
        ...cacheData.backups,
        nodes: backupNodes,
      },
    },
  });
};

export const writeNewBackupsCache = (variables: IBackupsQueryVariables, data: PartialDeep<IBackup>) => {
  const cacheData = cloneDeep(
    client.readQuery({
      query: BackupsDocument,
      variables,
    }),
  );

  if (!cacheData) return;

  client.writeQuery({
    query: BackupsDocument,
    variables,
    data: {
      backups: {
        ...cacheData.backups,
        nodesCount: cacheData.backups.nodesCount + 1,
        nodes: [{ ...data }, ...cacheData.backups.nodes],
      },
    },
  });
};

export const updateBackupMembersCache = (id: string, data: PartialDeep<IBackupMember>[]) => {
  const cacheData = cloneDeep<IBackupQuery | null>(
    client.readQuery({
      query: BackupDocument,
      variables: {
        backupId: id,
      },
    }),
  );

  if (!cacheData || !data) return;

  client.writeQuery({
    query: BackupDocument,
    variables: {
      backupId: id,
    },
    data: {
      me: {
        ...cacheData.me,
      },
      backup: {
        ...cacheData.backup,
        backupMembers: [...data],
      },
    },
  });
};
