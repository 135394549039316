import { Button } from 'antd';

import { TextBlock } from 'src/components/Misc';
import { useAddDeposit } from 'src/context/deposit';

const AssetAddedContainer = () => {
  const {
    ui: { setInitialData },
    refetch,
  } = useAddDeposit();

  const handleClick = () => {
    setInitialData();
    refetch();
  };

  return (
    <div className="text-center">
      <TextBlock
        title={`Your assets have been\nadded!`}
        text="Your assets are successfully connected to this escrow."
        className="mb-4"
        classes={{
          title: 'text-lg',
        }}
      />
      <Button
        type="primary"
        onClick={handleClick}
        style={{
          width: 90,
        }}
      >
        OK
      </Button>
    </div>
  );
};

export default AssetAddedContainer;
