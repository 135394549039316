import { Dropdown, Button } from 'antd';
import { EllipsisOutlined, ExpandAltOutlined, DeleteOutlined } from '@ant-design/icons';

import { BackupStatusEnum, BackupMemberRoleEnum } from 'src/graphql/schema';

import styles from './BackupItem.module.scss';

const actionKeys = ['remove', 'view'] as const;

type Props = {
  status: BackupStatusEnum;
  memberRole: BackupMemberRoleEnum;
  onClick: (key: (typeof actionKeys)[number]) => void;
};

const items = (status: BackupStatusEnum, memberRole: BackupMemberRoleEnum) => {
  return [
    {
      label: (
        <div className={styles['dropdown-item']}>
          <ExpandAltOutlined className={styles.icon} />
          <strong>View</strong>
        </div>
      ),
      key: 'view',
    },
    ...(status !== BackupStatusEnum.Removed && memberRole === BackupMemberRoleEnum.Owner
      ? [
          {
            label: (
              <div className={styles['dropdown-item']}>
                <DeleteOutlined className={styles.icon} />
                <strong>Remove</strong>
              </div>
            ),
            key: 'remove',
          },
        ]
      : []),
  ];
};

const BackupItemDropdown = ({ status, memberRole, onClick }: Props) => {
  return (
    <Dropdown
      arrow
      menu={{
        items: items(status, memberRole),
        onClick: (e) => {
          e.domEvent.preventDefault();
          onClick(e.key as (typeof actionKeys)[number]);
        },
      }}
      trigger={['click']}
      placement="bottomLeft"
      overlayClassName="ck-backup-item__dropdown"
    >
      <Button type="text" onClick={(e) => e.preventDefault()}>
        <EllipsisOutlined />
      </Button>
    </Dropdown>
  );
};

export default BackupItemDropdown;
