import classNames from 'classnames';

import styles from './JourneyModalLayout.module.scss';

const JourneyModalMain = ({
  children,
  className,
  withPadding = false,
}: React.PropsWithChildren<{ className?: string; withPadding?: boolean }>) => {
  const wrapperClasses = classNames(styles['main-outlet__wrapper'], className, {
    [styles['with--padding']]: withPadding,
  });

  return (
    <div className={styles['main-outlet']}>
      <div className={wrapperClasses}>{children}</div>
    </div>
  );
};

export default JourneyModalMain;
