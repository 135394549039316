import React from 'react';
import classNames from 'classnames';

import styles from './TextBlock.module.scss';

type Props = {
  title: string;
  titleColor?: 'light-blue' | 'dark-blue';
  text: string;
  subTitle?: string;
  segment?: React.ReactNode;
  className?: string;
  as?: 'h2' | 'h3' | 'h4';
  classes?: Partial<{
    title: string;
    subtitle: string;
    text: string;
  }>;
};

const TextBlock: React.FunctionComponent<Props> = ({
  title,
  text,
  subTitle,
  className,
  segment,
  as = 'h2',
  titleColor = 'light-blue',
  classes = {},
}) => {
  const Heading = as;

  return (
    <div className={classNames(styles.block, className)}>
      <Heading className={classNames(styles.title, styles[`color--${titleColor}`], classes.title)}>
        {title}
        {segment && segment}
      </Heading>
      {subTitle && <h3 className={classNames(styles.subtitle, classes.subtitle)}>{subTitle}</h3>}
      <p className={classNames(styles.text, classes.text)}>{text}</p>
    </div>
  );
};

export default TextBlock;
