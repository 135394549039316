import { LazyLoad } from 'src/components/Misc';
const BackupPlatformNewContainer = () => (
  <LazyLoad loader={() => import('src/containers/Backup/Platform/BackupPlatformNewContainer')} />
);
const BackupNewSettingsContainer = () => (
  <LazyLoad loader={() => import('src/containers/Backup/Settings/BackupNewSettingsContainer')} />
);

import { BackupRoutesEnum } from './';

import type { RouteObject } from 'react-router-dom';

export const backupNewRouteElements: RouteObject[] = [
  {
    path: BackupRoutesEnum.Platform,
    element: <BackupPlatformNewContainer />,
  },
  {
    path: BackupRoutesEnum.Settings,
    element: <BackupNewSettingsContainer />,
  },
];
