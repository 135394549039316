import React from 'react';
import { Skeleton } from 'antd';

import styles from './JourneyModalLayout.module.scss';

type Props = { justify?: 'start' | 'end' | 'space-between' };

const JourneyModalFooter = ({ children, justify = 'start' }: React.PropsWithChildren<Props>) => {
  return <div className={`${styles.footer} ${styles[`justify-${justify}`]}`}>{children}</div>;
};

const JourneyModalFooterGroup = ({ children }: React.PropsWithChildren) => {
  if (!children) return null;

  return <div className={styles['footer-group']}>{children}</div>;
};

export const JourneyModalFooterItem = ({ children }: React.PropsWithChildren) => (
  <div className={styles['footer-item']}>{children}</div>
);

export const JourneyModalFooterSkeleton = () => {
  return (
    <div className={styles.footer}>
      <Skeleton.Button active size="default" block />
    </div>
  );
};

JourneyModalFooter.Item = React.memo(JourneyModalFooterItem);
JourneyModalFooter.Group = React.memo(JourneyModalFooterGroup);
JourneyModalFooter.Skeleton = React.memo(JourneyModalFooterSkeleton);

export default JourneyModalFooter;
