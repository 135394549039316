import { Form, Select, Row } from 'antd';
import { forwardRef, useEffect } from 'react';
import { FormInstance } from 'antd/lib/form';

import { TextBlock } from 'src/components/Misc';
import { storageRegionOptions } from 'src/components/Escrow/Notifications/escrowNotifications.enum.ts';
import { DisruptionNotificationEnum, DisruptionNotificationGracePeriodEnum } from 'src/graphql/schema';

import { versionsOptions, disruptedNotificationsOptions, gracePeriodOptions } from '../../backup.enum';

import type { FormProps } from 'antd/lib/form';

import { settingsFieldsChange } from './lib';
import styles from './BackupSettingsForm.module.scss';

export type IBackupSettingsForm = {
  versionsLimit: string;
  disruptionNotification: DisruptionNotificationEnum;
  disruptionNotificationGracePeriod: DisruptionNotificationGracePeriodEnum | 'none';
  storageRegion: string;
};

type Props = {
  onSubmit: (data: IBackupSettingsForm) => void;
  defaultStorageRegion?: string;
};

type Ref = FormInstance;

const BackupSettingsForm = forwardRef<Ref, Props>(({ onSubmit, defaultStorageRegion }, ref) => {
  const [form] = Form.useForm<IBackupSettingsForm>();
  const currentDisruptionNotification = Form.useWatch('disruptionNotification', form);
  const isNoDisruptionNotifications =
    currentDisruptionNotification === DisruptionNotificationEnum.NoDistruptionNotifications;

  useEffect(() => {
    form.setFieldsValue({
      disruptionNotificationGracePeriod: isNoDisruptionNotifications
        ? undefined
        : form.getFieldValue('disruptionNotificationGracePeriod'),
    });
  }, [form, isNoDisruptionNotifications]);

  const onFieldsChange: FormProps['onFieldsChange'] = (_, allFields) => settingsFieldsChange(form)(_, allFields);

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      onFieldsChange={onFieldsChange}
      ref={ref}
      role="form"
      initialValues={{
        storageRegion: defaultStorageRegion,
      }}
    >
      <div className={styles.block}>
        <TextBlock
          as="h4"
          titleColor="dark-blue"
          title="Backup Version History"
          segment={<sub className={styles.segment}>Required</sub>}
          text="Keep a version history of your backups."
          className="mb-3"
        />
        <Form.Item
          name="versionsLimit"
          className={styles.select}
          rules={[
            {
              required: true,
              message: 'Version is required',
            },
          ]}
        >
          <Select placeholder="Select version" options={versionsOptions} />
        </Form.Item>
      </div>

      <div className={styles.block}>
        <TextBlock
          as="h4"
          titleColor="dark-blue"
          title="Disrupted Connection Notifications"
          segment={<sub className={styles.segment}>Required</sub>}
          text="Set up alerts if your backup process is interrupted."
          className="mb-3"
        />

        <div className={styles['container--634']}>
          <Row gutter={[0, 0]} wrap={false}>
            <Form.Item
              name="disruptionNotification"
              className={styles.select}
              rules={[
                {
                  required: true,
                  message: 'Notification is required',
                },
              ]}
            >
              <Select placeholder="Select notification" options={disruptedNotificationsOptions} />
            </Form.Item>
            <Form.Item name="disruptionNotificationGracePeriod" className={styles['select--320']}>
              <Select
                disabled={isNoDisruptionNotifications}
                placeholder="Select grace period"
                options={gracePeriodOptions}
              />
            </Form.Item>
          </Row>
        </div>
      </div>

      <TextBlock
        as="h4"
        titleColor="dark-blue"
        title="Storage Region"
        segment={<sub className={styles.segment}>Required</sub>}
        text="Should deposits be limited to a specific data storage region? (needs Advanced Deposits)"
        className="mb-3"
      />
      <Form.Item
        name="storageRegion"
        className={styles.select}
        rules={[
          {
            required: true,
            message: 'Region is required',
          },
        ]}
      >
        <Select placeholder="Select region" options={storageRegionOptions} />
      </Form.Item>
    </Form>
  );
});

BackupSettingsForm.displayName = 'BackupSettingsForm';

export default BackupSettingsForm;
