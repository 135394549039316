import { BackupMemberRoleEnum } from 'src/graphql/schema';

import type { IBackupResultItemData } from '../BackupResultItemContent';

export const shouldHideDownloadAction = (
  currentUserRole: BackupMemberRoleEnum,
  status: IBackupResultItemData['type'],
  lastBackup?: string | null,
) =>
  [
    currentUserRole !== BackupMemberRoleEnum.Owner,
    lastBackup === null,
    status === 'scheduled',
    status === 'failed',
  ].some(Boolean);
