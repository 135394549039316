import { useAddDepositSlice } from 'src/slices';

import { createRequiredContext } from '../createRequiredContext';

type Props = {
  children: React.ReactNode;
  refetch: () => void;
};

const [useAddDeposit, AddDepositProvider] = createRequiredContext<{
  ui: ReturnType<typeof useAddDepositSlice>;
  refetch: () => void;
}>();

const AddDepositContextProvider = ({ refetch, children }: Props) => {
  const ui = useAddDepositSlice();
  const providerValue = {
    ui,
    refetch,
  };

  return <AddDepositProvider value={providerValue}>{children}</AddDepositProvider>;
};

export { useAddDeposit, AddDepositContextProvider };
