import { IconFile, IconProvider, IconSummary, IconFilter, IconParties, IconChartPie } from 'src/components/Svg/Icons';
import { BackupRoutesEnum } from 'src/pages/Backup';

import type { IBackupMenuItem } from './BackupMenuItem';

export const backupMenuItems: IBackupMenuItem[] = [
  {
    path: BackupRoutesEnum.Platform,
    label: 'Platform',
    icon: IconProvider,
    isRequired: true,
  },
  {
    path: BackupRoutesEnum.Settings,
    label: 'Settings',
    icon: IconFilter,
    isDisabled: true,
  },
  {
    path: BackupRoutesEnum.Members,
    label: 'Members',
    icon: IconParties,
    isDisabled: true,
  },
  {
    path: BackupRoutesEnum.Assets,
    label: 'Assets',
    icon: IconFile,
    isDisabled: true,
  },
  {
    path: BackupRoutesEnum.Summary,
    label: 'Summary',
    icon: IconSummary,
    isDisabled: true,
  },
  {
    path: BackupRoutesEnum.Reports,
    label: 'Reports',
    icon: IconChartPie,
    isDisabled: true,
  },
];
