import { message } from 'antd';
import dayjs from 'dayjs';

import { BackupResultItem, BackupResultItemWithHistory, IBackupResultItemData } from 'src/components/Backup/Result';
import {
  useBackupVersionsLazyQuery,
  useRequestBackupVersionDownloadMutation,
  BackupStatusEnum,
} from 'src/graphql/schema';
import { ClientError, getApiError } from 'src/utils/errors';

import type { IBackupAsset, IBackupVersion, BackupMemberRoleEnum } from 'src/graphql/schema';

import { versionTextOptions, noBackupText, resultItemStatus } from './backup-summary.enum';

type Props = {
  data: IBackupAsset;
  currentUserRole: BackupMemberRoleEnum;
  showConfirmationModal: () => void;
  backupStatus: BackupStatusEnum;
};

const BackupResultItemContainer = ({ data, currentUserRole, showConfirmationModal, backupStatus }: Props) => {
  const [getBackupVersions, { data: versionsData, loading }] = useBackupVersionsLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [requestBackupVersionDownload] = useRequestBackupVersionDownloadMutation();

  const onDownload = async (id: string) => {
    try {
      const { data: dataRequest } = await requestBackupVersionDownload({
        variables: {
          backupAssetId: data.id,
          backupVersionId: id,
        },
      });

      if (dataRequest?.requestBackupVersionDownload?.success) showConfirmationModal();
      else throw new ClientError(dataRequest?.requestBackupVersionDownload?.errors?.[0]);
    } catch (error: unknown) {
      getApiError(error, message.error);
    }
  };

  const onShowHistory = async () => {
    try {
      await getBackupVersions({
        variables: {
          id: data.id,
          perPage: 100,
        },
      });
    } catch (error: unknown) {
      if (error instanceof Error) message.error(error.message);
    }
  };

  const noBackupNeeded =
    data.lastUpdateCheck && data.lastBackup && dayjs(data.lastUpdateCheck).valueOf() > dayjs(data.lastBackup).valueOf();

  const dataProps = {
    id: data.id,
    name: data.name,
    version: versionTextOptions[data.status] ?? '',
    date: !noBackupNeeded && data.lastBackup ? dayjs(data.lastBackup).format('M-D-YYYY, h:mmA') : '',
    type: resultItemStatus[data.status],
    lastBackup: data.lastBackup,
  };

  if (backupStatus === BackupStatusEnum.Removed) {
    return <BackupResultItem className="mb-12" data={dataProps} />;
  }

  return (
    <BackupResultItemWithHistory
      className="mb-12"
      onDownload={onDownload}
      onShowHistory={onShowHistory}
      isLoading={loading}
      currentUserRole={currentUserRole}
      data={{
        ...dataProps,
        lastBackupVersionId: data.lastBackupVersionId ?? '',
        text: noBackupNeeded ? noBackupText : '',
        children: versionsData?.backupVersions.nodes.map(getVersion) ?? [],
      }}
    />
  );
};

export default BackupResultItemContainer;

function getVersion(data: IBackupVersion, index: number, array: unknown[]): IBackupResultItemData {
  return {
    id: data.id,
    version: `Version ${array.length - index}`,
    date: dayjs(data.createdAt).format('M-D-YYYY, h:mmA'),
    type: resultItemStatus[data.status],
  };
}
