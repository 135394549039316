import { Fragment, useState } from 'react';
import { Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';

import { ModalProceed, ModalConfirmation } from 'src/components/Misc';
import { useGenerateAgreementMutation } from 'src/graphql/schema';
import { useEscrow } from 'src/context/escrow';

type Props = {
  escrowId: string;
  isGeneratable: boolean;
};

const GenerateAgreementActionContainer = ({ escrowId, isGeneratable }: Props) => {
  const [ui, setUi] = useState({
    isConfirmModalOpen: false,
    isProceedModalOpen: false,
    isLoading: false,
  });
  const { refetch } = useEscrow();
  const [generateAgreement] = useGenerateAgreementMutation();
  const navigate = useNavigate();

  const onGenerate = async () => {
    setUi((prevUi) => ({ ...prevUi, isLoading: true }));

    try {
      const { data } = await generateAgreement({
        variables: {
          escrowId,
        },
      });

      if (data?.generateAgreement?.success) {
        setUi((prevUi) => ({ ...prevUi, isProceedModalOpen: false, isConfirmModalOpen: true }));
      } else {
        throw new Error(data?.generateAgreement?.errors?.[0]);
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        message.error(err.message || 'Something went wrong!');
      }
    } finally {
      setUi((prevUi) => ({ ...prevUi, isLoading: false }));
    }
  };

  const onCloseConfirmation = async () => {
    await refetch();
    setUi((prevUi) => ({ ...prevUi, isConfirmModalOpen: false }));
    navigate('/');
  };

  return (
    <Fragment>
      <Button
        type="primary"
        disabled={!isGeneratable}
        onClick={() => setUi((prevUi) => ({ ...prevUi, isProceedModalOpen: true }))}
      >
        Generate Agreement
      </Button>

      <ModalProceed
        title="Are you sure you have selected the correct settings for this escrow?"
        text="Codekeeper will use the settings you selected to create an escrow agreement. Clicking Generate will save these settings, and send them for our review."
        isOpen={ui.isProceedModalOpen}
        proceedText="Generate Agreement"
        onCancel={() => setUi((prevUi) => ({ ...prevUi, isProceedModalOpen: false }))}
        onProceed={onGenerate}
        isLoading={ui.isLoading}
      />

      <ModalConfirmation
        isOpen={ui.isConfirmModalOpen}
        title="Agreement generation in progress!"
        text="Thank you for submitting the information for your escrow. We will prepare the agreement, and let you know when it is ready for review."
        onClose={onCloseConfirmation}
        okText="Back to Dashboard"
      />
    </Fragment>
  );
};

export default GenerateAgreementActionContainer;
