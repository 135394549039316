import { useRef } from 'react';
import { Modal, message } from 'antd';

import { SaasEditProviderForm, ISaasEditProviderForm } from 'src/components/Saas/Provider';
import { useSaasEscrow } from 'src/context/saas';
import {
  useUpdateSaasProviderMutation,
  SaasEnvironmentDocument,
  SaasProviderRoleEnum,
  SaasProviderProtectionPeriodEnum,
} from 'src/graphql/schema';
import { getApiError, ClientError } from 'src/utils/errors';

const EditProviderContainer = () => {
  const resetRef = useRef<{ onResetFields: () => void }>(null);
  const { environment, ui: { provider } = {}, hideProvider } = useSaasEscrow();
  const [updateSaasProvider, { loading }] = useUpdateSaasProviderMutation({
    refetchQueries: [SaasEnvironmentDocument],
  });
  const providerData = environment?.saasProviders?.find((item) => item.id === provider?.id);

  const onSubmit = async (values: ISaasEditProviderForm) => {
    try {
      const { data } = await updateSaasProvider({
        variables: {
          input: {
            saasProviderId: provider?.id || '',
            saasProviderParams: {
              role: values.role as SaasProviderRoleEnum,
              protectionPeriod: values.continuity as SaasProviderProtectionPeriodEnum,
              description: values.description,
              contactEmail: values.email,
              monthlyCost: values.cost,
            },
          },
        },
      });

      if (data?.updateSaasProvider?.success) {
        message.success('Provider updated successfully');
        onReset();
      } else {
        throw new ClientError(data?.updateSaasProvider?.errors?.[0]);
      }
    } catch (error: unknown) {
      getApiError(error, message.error);
    }
  };

  const onReset = () => {
    resetRef.current?.onResetFields();
    hideProvider();
  };

  return (
    <Modal
      open={provider?.modalType === 'edit'}
      footer={null}
      title={`Enter your ${providerData?.platform} details`}
      width={550}
      onCancel={onReset}
    >
      <SaasEditProviderForm
        ref={resetRef}
        onSubmit={onSubmit}
        onCancel={onReset}
        isLoading={loading}
        {...(providerData && {
          initialValues: {
            role: providerData.role,
            description: providerData.description,
            email: providerData.contactEmail,
            cost: providerData.monthlyCost,
            continuity: providerData.protectionPeriod,
          },
        })}
      />
    </Modal>
  );
};

export default EditProviderContainer;
