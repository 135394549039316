import { Fragment, useState } from 'react';
import { Button, message } from 'antd';

import { useSaasEscrow } from 'src/context/saas';
import { ModalProceed, ModalConfirmation } from 'src/components/Misc';
import { useActivateSaasEnvironmentMutation } from 'src/graphql/schema';

const ActivateSaasEnvironmentContainer = () => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isProceedModalOpen, setIsProceedModalOpen] = useState(false);

  const { environment, refetch } = useSaasEscrow();
  const [activateEnvironment, { loading }] = useActivateSaasEnvironmentMutation();

  const onActivateEnvironment = async () => {
    try {
      const { data } = await activateEnvironment({
        variables: { id: environment?.id || '' },
      });

      if (data?.activateSaasEnvironment?.success) {
        setIsProceedModalOpen(false);
        setIsConfirmModalOpen(true);
      } else {
        throw new Error(data?.activateSaasEnvironment?.errors?.[0]);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        message.error(error.message || 'Something went wrong');
      }
    }
  };

  const onCloseConfirmation = async () => {
    await refetch();
    setIsConfirmModalOpen(false);
  };

  return (
    <Fragment>
      <Button type="primary" disabled={!environment.saasProviders.length} onClick={() => setIsProceedModalOpen(true)}>
        Activate SaaS Escrow
      </Button>

      <ModalProceed
        title="Are you sure you want to submit this SaaS escrow configuration?"
        text="By confirming, you acknowledge that we will be authorized to access the billing details of these providers and take over ownership of your account during a disruption."
        isOpen={isProceedModalOpen}
        onCancel={() => setIsProceedModalOpen(false)}
        onProceed={onActivateEnvironment}
        isLoading={loading}
      />

      <ModalConfirmation
        isOpen={isConfirmModalOpen}
        title="SaaS escrow configuration sent!"
        text="We'll reach out with the next steps on how to set up each platform in your environment. Please be on the lookout for our instruction email soon."
        onClose={onCloseConfirmation}
      />
    </Fragment>
  );
};

export default ActivateSaasEnvironmentContainer;
