import { Modal, Button, message } from 'antd';
import { useState, Fragment } from 'react';

import { UserTwoFAForm, IUserTwoFAForm } from 'src/components/Profile/Forms';
import { useAuth } from 'src/context/auth/use-auth';
import { TextBlock } from 'src/components/Misc';

type Props = {
  setTOTPEnabled: (isEnabled: boolean) => void;
};

const DisableTOTPContainer = ({ setTOTPEnabled }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { verifyTOTPSetup, updateTOTP } = useAuth();

  const onDisable = () => {
    setIsOpen(true);
  };

  const onSubmit = async ({ code }: IUserTwoFAForm) => {
    setIsLoading(true);

    try {
      await verifyTOTPSetup(String(code));
      await updateTOTP(false);

      setIsOpen(false);
      setTOTPEnabled(false);
    } catch (e: unknown) {
      if (e instanceof Error) message.error(e.message || 'Something went wrong!');
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => setIsOpen(false);

  return (
    <Fragment>
      <Button type="default" onClick={onDisable}>
        Disable
      </Button>

      <Modal open={isOpen} onCancel={onCancel} footer={null} width={455}>
        <TextBlock
          className="mb-32"
          title="Are you sure you want to turn off multi-factor authentication?"
          text="The second authentication method will no longer be required when signing in to your Codekeeper account. To continue, enter the 6-digit authentication code and click the Turn Off button."
        />
        <UserTwoFAForm onSubmit={onSubmit} onCancel={onCancel} isLoading={isLoading} submitText="Turn Off" />
      </Modal>
    </Fragment>
  );
};

export default DisableTOTPContainer;
