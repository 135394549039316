import { Dropdown, Button } from 'antd';
import { EllipsisOutlined, ExpandAltOutlined, FormOutlined, DeleteOutlined } from '@ant-design/icons';

import { SaasEnvironmentStatusEnum } from 'src/graphql/schema';

import styles from './SaasProviderCard.module.scss';

const actionKeys = ['view', 'edit', 'remove'] as const;

type Props = {
  environmentStatus: SaasEnvironmentStatusEnum;
  onClick?: (key: (typeof actionKeys)[number]) => void;
};

const items = (environmentStatus: SaasEnvironmentStatusEnum) => [
  {
    label: (
      <div className={styles['dropdown-item']}>
        <ExpandAltOutlined className={styles.icon} />
        <strong>View details</strong>
      </div>
    ),
    key: actionKeys[0],
  },
  ...(environmentStatus !== SaasEnvironmentStatusEnum.Pending
    ? [
        {
          label: (
            <div className={styles['dropdown-item']}>
              <FormOutlined className={styles.icon} />
              <strong>Edit details</strong>
            </div>
          ),
          key: actionKeys[1],
        },
        {
          label: (
            <div className={styles['dropdown-item']}>
              <DeleteOutlined className={styles.icon} />
              <strong>Remove</strong>
            </div>
          ),
          key: actionKeys[2],
        },
      ]
    : []),
];

const ProviderCardDropdown = ({ environmentStatus, onClick }: Props) => {
  return (
    <Dropdown
      arrow
      menu={{
        items: items(environmentStatus),
        ...(onClick && {
          onClick: (e) => onClick(e.key as (typeof actionKeys)[number]),
        }),
      }}
      trigger={['click']}
      placement="bottomLeft"
      overlayClassName="ck-provider__dropdown"
    >
      <Button type="text">
        <EllipsisOutlined />
      </Button>
    </Dropdown>
  );
};

export default ProviderCardDropdown;
