import { useNavigate } from 'react-router-dom';
import { Fragment, useState } from 'react';
import { message } from 'antd';
import { useReadQuery } from '@apollo/client';

import { useRemoveBackupMutation, BackupMemberRoleEnum } from 'src/graphql/schema';
import { BackupList } from 'src/components/Backup/List';
import { ModalProceed } from 'src/components/Misc';
import { BackupEmpty } from 'src/components/Backup/Empty';
import { updateBackupsCache } from 'src/graphql/client/cache/backups';
import { useProfile } from 'src/context/profile';
import { withSuspense } from 'src/hoc';

import type { PartialDeep } from 'type-fest';
import type { IBackupItem } from 'src/components/Backup/List';
import type { IBackup, IBackupsQuery } from 'src/graphql/schema';
import type { QueryRef } from '@apollo/client';

const BackupsContainer = ({ queryRef }: { queryRef: QueryRef<IBackupsQuery> }) => {
  const [removeBackupId, setRemoveBackupId] = useState('');

  const navigate = useNavigate();
  const { data } = useReadQuery<IBackupsQuery>(queryRef);

  // TODO: Refactor ProfileContextProvider to use suspense pattern
  const { profile, isLoading: loadingProfile } = useProfile();
  const [removeBackup, { loading: removing }] = useRemoveBackupMutation();

  if (loadingProfile) return <BackupList.Skeleton />;

  if (!data?.backups?.nodesCount)
    return (
      <div className="d-flex align-items-center justify-content-center ck-backup__empty">
        <BackupEmpty type="new" className="mt-50" />
      </div>
    );

  const dropdownItemClick = (type: string, id: string) => {
    switch (type) {
      case 'view':
        navigate(`/backups/${id}/platform`);
        break;
      case 'remove':
        setRemoveBackupId(id);
        break;
      default:
        throw new Error('Unknown dropdown item type');
    }
  };

  const onRemove = async () => {
    try {
      const { data } = await removeBackup({
        variables: {
          id: removeBackupId,
        },
      });

      if (data?.removeBackup?.success) {
        message.success('Backup has been removed');

        if (data?.removeBackup?.backup) {
          updateBackupsCache({ perPage: 100 }, data.removeBackup.backup as PartialDeep<IBackup>);
        }
        setRemoveBackupId('');
      } else {
        throw new Error(data?.removeBackup?.errors?.[0]);
      }
    } catch (error: unknown) {
      if (error instanceof Error) message.error(error.message || 'Something went wrong');
    }
  };

  const onCancel = () => setRemoveBackupId('');

  return (
    <Fragment>
      <BackupList
        items={data?.backups?.nodes.map((item) => getBackup(item as IBackup, profile.id)) ?? []}
        dropdownItemClick={dropdownItemClick}
      />

      <ModalProceed
        isOpen={Boolean(removeBackupId)}
        title="Are you sure you want to remove this backup?"
        text="By confirming, you will be removing all backup versions from our secure storage."
        isLoading={removing}
        onCancel={onCancel}
        onProceed={onRemove}
      />
    </Fragment>
  );
};

export default withSuspense(BackupsContainer, BackupList.Skeleton);

function getBackup(backup: IBackup, userId: string): IBackupItem {
  return {
    id: backup.id,
    status: backup.status,
    integration: {
      accountName: backup?.integration?.accountName || '',
      provider: backup?.integration?.provider,
    },
    assetsCount: backup.backupAssets?.length || 0,
    memberRole:
      backup.backupMembers?.find((member) => member.user.id === userId)?.role ?? BackupMemberRoleEnum.Administrator,
  };
}
