/* eslint-disable react/display-name */
import { Row, Col, Skeleton } from 'antd';
import classNames from 'classnames';

import type { SaasEnvironmentStatusEnum } from 'src/graphql/schema';

import ProviderCardItem, { IProviderCardItem } from './ProviderCardItem';
import styles from './SaasProviderCard.module.scss';

const colToSpanEnum = {
  4: 6,
  6: 4,
} as const;

type Props = {
  items: IProviderCardItem[];
  dropdownItemClick: (type: string, id: string) => void;
  action?: React.ReactNode;
  heading: {
    size: 'small' | 'medium';
    text: string;
  };
  environmentStatus: SaasEnvironmentStatusEnum;
  col?: keyof typeof colToSpanEnum;
  disableItems?: boolean;
};

const ProviderCardList = ({
  heading,
  items,
  dropdownItemClick,
  action,
  environmentStatus,
  col = 6,
  disableItems,
}: Props) => {
  if (!items.length) return null;

  const containerClasses = classNames(styles['list-container'], {
    [styles.medium]: col === 6,
    [styles.large]: col === 4,
  });

  return (
    <div className={styles.list}>
      <div className={`${styles['list-heading']} ${styles[heading.size]}`}>{heading.text}</div>

      <div className={containerClasses}>
        <Row gutter={[16, 16]}>
          {items.map((item) => {
            const handleClick = (type: string) => dropdownItemClick(type, item.id);

            return (
              <Col key={item.id} span={col} className={styles.col}>
                <ProviderCardItem
                  environmentStatus={environmentStatus}
                  provider={{ ...item, disabled: disableItems }}
                  dropdownItemClick={handleClick}
                />
              </Col>
            );
          })}

          {action && (
            <Col span={6} className={styles.col}>
              {action}
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

ProviderCardList.Skeleton = function ({ col = 4, items = 2 }: { col?: keyof typeof colToSpanEnum; items?: 2 | 3 | 4 }) {
  return (
    <div className={styles.list} aria-label="Loading...">
      <div className={`${styles['list-heading']} ${styles['medium']}`}>
        <Skeleton.Input style={{ width: 300 }} active={true} size="small" />
      </div>

      <Row gutter={[16, 16]}>
        {Array.from({ length: items }).map((_, index) => (
          <Col key={index} span={col}>
            <div className={styles.card}>
              <Skeleton />
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ProviderCardList;
