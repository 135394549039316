import { useState } from 'react';
import { message } from 'antd';
import classNames from 'classnames';

import { useAuth } from 'src/context/auth/use-auth';
import { ResetPasswordAlert } from 'src/components/Authentication';
import LogingForm, { ILoginForm } from 'src/components/Authentication/Login/LoginForm';

const LoginContainer = () => {
  const [isSigningIn, setIsSigningIn] = useState(false);

  const { signIn } = useAuth();

  const onFinish = async ({ email, password }: ILoginForm) => {
    setIsSigningIn(true);

    try {
      await signIn(email, password);
    } catch (e: unknown) {
      if (e instanceof Error) message.error(e.message);
      setIsSigningIn(false);
    }
  };

  const hasResetPswd = localStorage.getItem('pswd-reset');

  return (
    <div className={classNames('invite-login-screen -login', { '-sm': hasResetPswd })}>
      <h2 className="mb-4">Sign in to your account</h2>

      {!hasResetPswd && <ResetPasswordAlert />}

      <LogingForm onFinish={onFinish} isLoading={isSigningIn} />
    </div>
  );
};

export default LoginContainer;
