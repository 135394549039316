import React from 'react';
import { Row, Col, Radio } from 'antd';
import classNames from 'classnames';
import { RadioChangeEvent } from 'antd/lib/radio';

import CustomRadioWithIcon, { ICustomRadioWithIcon } from './CustomRadioWithIcon';

type Props = {
  options: ICustomRadioWithIcon[];
  onChange: (value: string) => void;
  col?: keyof typeof colToSpanEnum;
  className?: string;
  selectedValue?: string;
};

const colToSpanEnum = {
  1: 24,
  2: 12,
  3: 8,
  4: 6,
  5: 5,
  6: 4,
  8: 3,
  12: 2,
} as const;

const CustomRadioWithIconGroup: React.FunctionComponent<Props> = ({
  className,
  options,
  col = 1,
  onChange,
  selectedValue,
}) => {
  const handleChange = (e: RadioChangeEvent) => onChange(e.target.value);

  return (
    <Radio.Group
      className={classNames('ck-custom-radio-group', className)}
      onChange={handleChange}
      value={selectedValue}
    >
      <Row gutter={[16, 16]} className="ck-custom-radio-group__row">
        {options.map((option) => (
          <Col key={option.value} span={colToSpanEnum[col]}>
            <CustomRadioWithIcon selected={selectedValue === option.value} {...option} />
          </Col>
        ))}
      </Row>
    </Radio.Group>
  );
};

export default CustomRadioWithIconGroup;
