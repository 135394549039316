import { BackupStatusIndicator, BackupStatusIndicatorEnum } from 'src/components/Backup/StatusIndicator';
import { useBackup } from 'src/context/backup';
import { BackupStatusEnum } from 'src/graphql/schema';

const BackupStatusIndicatorContainer = () => {
  const { backup } = useBackup();

  let currentStatus = BackupStatusIndicatorEnum.CONNECT;

  if (backup.status === BackupStatusEnum.Draft) currentStatus = BackupStatusIndicatorEnum.SELECT_ASSETS;

  if (backup.status === BackupStatusEnum.Stopped) currentStatus = BackupStatusIndicatorEnum.STOPPED;

  if (backup.status === BackupStatusEnum.Removed) currentStatus = BackupStatusIndicatorEnum.REMOVED;

  if (backup.status === BackupStatusEnum.Active) currentStatus = BackupStatusIndicatorEnum.BACKUP_INITIATED;

  return <BackupStatusIndicator currentStatus={currentStatus} />;
};

export default BackupStatusIndicatorContainer;
