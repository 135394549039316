import { useState, Fragment, useEffect, useTransition } from 'react';
import { message } from 'antd';
import { useSuspenseQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import { SaasEscrowsTable, SaasEscrowsActions, ISaasEscrowTableRow } from 'src/components/Saas/Escrows';
import { EmptySaas } from 'src/components/Saas/Empty';
import {
  useRemoveSaasEnvironmentMutation,
  SaasEnvironmentsDocument,
  SaasEnvironmentStatusEnum,
} from 'src/graphql/schema';
import { ModalProceed } from 'src/components/Misc';
import { updateSaasEscrowsCache } from 'src/graphql/client/cache';
import { withSuspense } from 'src/hoc';
import { SAAS_ESCROWS_LIMIT } from 'src/shared/constants';

import CreateNewEnvironmentContainer from '../Environments/CreateNewEnvironmentContainer';

import type { ISaasEnvironment, ISaasEnvironmentsQuery } from 'src/graphql/schema';

const SaasEscrowsContainer = () => {
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState<SaasEnvironmentStatusEnum | ''>('');
  const [saasEnvironmentId, setSaasEnvironmentId] = useState('');
  const [isPending, startTransition] = useTransition();
  const location = useLocation();

  const variables = {
    page: activePage,
    perPage: SAAS_ESCROWS_LIMIT,
    ...(selectedFilter && { status: selectedFilter }),
  };
  const { data } = useSuspenseQuery<ISaasEnvironmentsQuery>(SaasEnvironmentsDocument, {
    fetchPolicy: 'cache-and-network',
    variables,
    queryKey: [location.pathname === '/saas-escrows'],
  });
  const [removeEscrow, { loading: loadingRemove }] = useRemoveSaasEnvironmentMutation();

  useEffect(() => {
    if (data) setTotal(data.saasEnvironments.nodesCount);
  }, [data]);

  const onSelect = async (value: string) => {
    startTransition(() => {
      setSelectedFilter(value === 'all' ? '' : (value as SaasEnvironmentStatusEnum));
      setActivePage(1);
    });
  };

  const onPageChange = async (page: number) => {
    startTransition(() => {
      setActivePage(page);
    });
  };

  const onRemoveEscrow = async () => {
    try {
      const { data } = await removeEscrow({
        variables: {
          id: saasEnvironmentId,
        },
      });
      if (data?.removeSaasEnvironment?.success) {
        message.success('Escrow removed!');
        setSaasEnvironmentId('');
        updateSaasEscrowsCache(saasEnvironmentId, variables);
      } else {
        throw new Error(data?.removeSaasEnvironment?.errors?.[0]);
      }
    } catch (error: unknown) {
      if (error instanceof Error) message.error(error.message || 'Something went wrong');
    }
  };

  if (!data.saasEnvironments.nodesCount && !selectedFilter)
    return (
      <div className="ck-saas__empty d-flex align-items-center justify-content-center">
        <EmptySaas
          type="environment"
          action={<CreateNewEnvironmentContainer titleButton="Create new escrow" size="large" />}
        />
      </div>
    );

  return (
    <Fragment>
      <SaasEscrowsActions onSelect={onSelect} />
      <SaasEscrowsTable
        data={data.saasEnvironments.nodes.map((node) => getEnvironment(node as ISaasEnvironment))}
        pagination={{
          current: activePage,
          total,
          onChange: onPageChange,
        }}
        isLoading={isPending}
        onRemove={setSaasEnvironmentId}
      />

      <ModalProceed
        isOpen={Boolean(saasEnvironmentId)}
        onProceed={onRemoveEscrow}
        onCancel={() => setSaasEnvironmentId('')}
        title="Delete escrow"
        text="Are you sure you want to delete this draft? All the configured data in this escrow will be deleted."
        proceedText="Delete"
        proceedButtonProps={{ danger: true, type: 'default' }}
        isLoading={loadingRemove}
      />
    </Fragment>
  );
};

export default withSuspense(SaasEscrowsContainer);

function getEnvironment({
  id,
  status,
  name,
  saasProviders,
  activatedAt,
  saasEnvironmentTermination,
}: ISaasEnvironment): ISaasEscrowTableRow {
  return {
    name,
    status: saasEnvironmentTermination ? 'terminated-requested' : status,
    providers: saasProviders?.length || 0,
    date: activatedAt,
    action: {
      id,
    },
  };
}
