import React from 'react';
import classNames from 'classnames';

import styles from './CardWithIcon.module.scss';

export type ICardWithIcon = (
  | { iconType: 'regular' }
  | {
      iconType: 'image';
      size: number; // size of the svg image
    }
) & {
  heading: string;
  text?: string;
  icon: React.MemoExoticComponent<(props: React.SVGProps<SVGSVGElement>) => React.JSX.Element>;
};

type Props = {
  className?: string;
} & ICardWithIcon;

const CardWithIcon: React.FunctionComponent<Props> = (props) => {
  const { className, icon: Icon, heading, text } = props;
  const cardClasses = classNames(styles.card, className);

  return (
    <div className={cardClasses}>
      <div className={styles['icon-wrapper']}>
        {props.iconType === 'image' ? (
          <Icon width={props.size} height={props.size} />
        ) : (
          <i className={styles.icon}>
            <Icon />
          </i>
        )}
      </div>
      <strong className={styles.heading}>{heading}</strong>
      {text && <div className={styles.text}>{text}</div>}
    </div>
  );
};

export default CardWithIcon;
