import React, { Fragment } from 'react';
import { Alert } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ApolloError } from '@apollo/client/errors';

import { JourneyModalHeader, JourneyModalMain, JourneyModalFooter } from './';

import styles from './JourneyModalLayout.module.scss';

export type Props = {
  header: React.ReactNode;
  menu: React.ReactNode;
  statusIndicator: React.ReactNode;
  error?: { goTo?: string } & ApolloError;
};

const JourneyModalLayout = ({ header, children, error, statusIndicator, menu }: React.PropsWithChildren<Props>) => {
  const navigate = useNavigate();

  const onClose = () => error?.goTo && navigate(error.goTo);

  if (error)
    return (
      <div className={styles['error-alert']}>
        <Alert message="Error" description={error.message} type="error" showIcon closable onClose={onClose} />
      </div>
    );

  return (
    <Fragment>
      {header}
      <div className={styles.layout}>
        {statusIndicator}
        {menu}

        <div className={styles.main}>
          <div className={styles['main-wrapper']}>{children}</div>
        </div>
      </div>
    </Fragment>
  );
};

JourneyModalLayout.Header = JourneyModalHeader;
JourneyModalLayout.Main = JourneyModalMain;
JourneyModalLayout.Footer = JourneyModalFooter;

export default JourneyModalLayout;
