import {
  PieChartFilled,
  FileTextFilled,
  DatabaseFilled,
  AppstoreFilled,
  SettingFilled,
  FileAddFilled,
  MessageFilled,
} from '@ant-design/icons';
import { Badge } from 'antd';

import { ImageEscrow, ImageSaasEscrow, ImageBackups } from 'src/components/Svg/Images';
import { IconRefresh } from 'src/components/Svg/Icons';

import type { IMenuItem } from './Sidebar';

import styles from './Sidebar.module.scss';

export const getMenuItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: IMenuItem[],
  type?: 'group',
) => {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as IMenuItem;
};

export const backupMenuItems: IMenuItem[] = [
  getMenuItem(
    <div className={styles['group-row']}>
      <ImageBackups className={styles['group-icon']} />
      <strong className={styles['group-label']}>Software Backup</strong>
    </div>,
    'backup',
    null,
    [getMenuItem('My Backups', '/backups', <IconRefresh />)],
    'group',
  ),
  {
    type: 'divider',
  },
];

export const escrowMenuItems: IMenuItem[] = [
  getMenuItem(
    <div className={styles['group-row']}>
      <ImageEscrow className={styles['group-icon']} />
      <strong className={styles['group-label']}>Software Escrow</strong>
    </div>,
    'escrow',
    null,
    [
      getMenuItem('Home', '/', <PieChartFilled />),
      getMenuItem('Escrows', '/escrows', <FileTextFilled />),
      getMenuItem('Deposits', '/deposits', <DatabaseFilled />),
    ],
    'group',
  ),
  {
    type: 'divider',
  },
];

export const saasMenuItems: IMenuItem[] = [
  getMenuItem(
    <div className={styles['group-row']}>
      <ImageSaasEscrow className={styles['group-icon']} />
      <strong className={styles['group-label']}>SaaS Escrow</strong>
    </div>,
    'saas',
    null,
    [
      getMenuItem('Escrows', '/saas-escrows', <FileTextFilled />),
      getMenuItem('Environments', '/saas-environments', <AppstoreFilled />),
    ],
    'group',
  ),
  {
    type: 'divider',
  },
];

export const otherMenuItems = ({
  migrationsCount = 0,
  withBilling = false,
}: {
  migrationsCount?: number;
  withBilling?: boolean;
}): IMenuItem[] => [
  getMenuItem('Integrations', '/integrations', <AppstoreFilled />),
  migrationsCount === 0
    ? null
    : getMenuItem(
        <span className={styles['item-migrations']}>
          Migrations{' '}
          {Boolean(migrationsCount) && (
            <Badge
              count={migrationsCount}
              color="#F5222D"
              style={{
                marginLeft: '5px',
              }}
            />
          )}
        </span>,
        '/migrations',
        <FileAddFilled />,
      ),
  getMenuItem('Support', '/support', <MessageFilled />),
  getMenuItem('Settings', 'settings', <SettingFilled />, [
    getMenuItem('Account', '/account'),
    ...(withBilling ? [getMenuItem('Billing', '/billing')] : []),
  ]),
];
