import { redirect } from 'react-router-dom';

import { BackupModalContainer, BackupPlatformNewContainer } from 'src/containers/Backup';
import { JourneyModal } from 'src/components/Journey/ModalLayout';

import { BackupsPage } from '../pages/Backups';
import { backupRouteElements, backupNewRouteElements, BackupRoutesEnum } from '../pages/Backup';

import type { RouteObject } from 'react-router-dom';

export const backupsRoutes: RouteObject = {
  path: 'backups',
  element: <BackupsPage />,
  children: [
    {
      element: <JourneyModal />,
      children: [
        {
          element: <BackupModalContainer />,
          children: [
            {
              path: 'new',
              element: <BackupPlatformNewContainer />,
            },
            {
              path: 'new/*',
              children: backupNewRouteElements,
            },
            {
              loader: ({ params }) => {
                const { id = null, '*': splat = '' } = params;

                if (id && !splat) return redirect(`/backups/${id}/${BackupRoutesEnum.Platform}`);

                const path = splat?.split('/').length > 1 ? splat?.split('/')[0] : splat;

                if (!Object.values(BackupRoutesEnum).includes(path as BackupRoutesEnum)) {
                  return redirect('/404');
                }

                return null;
              },
              path: ':id/*',
              children: backupRouteElements,
            },
          ],
        },
      ],
    },
  ],
};
