import { cloneDeep } from '@apollo/client/utilities';

import { SaasEnvironmentsDocument } from 'src/graphql/schema';

import { apolloClient as client, apolloCache as cache } from '../apollo-client';

import type { ISaasEnvironmentsQuery, ISaasEnvironmentsQueryVariables } from 'src/graphql/schema';

export const updateSaasEscrowsCache = (id: string, variables?: ISaasEnvironmentsQueryVariables) => {
  const cacheData = cloneDeep(
    client.readQuery<ISaasEnvironmentsQuery | null>({ query: SaasEnvironmentsDocument, variables }),
  );

  if (!cacheData) return;

  const node = cacheData.saasEnvironments.nodes.find((node) => node.id === id);

  if (!node) return;

  cache.modify({
    id: cache.identify(node),
    fields(_fieldValue, { DELETE }) {
      return DELETE;
    },
  });
};
