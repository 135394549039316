import { useState, Fragment } from 'react';
import { Modal, Button, message } from 'antd';
import QRCode from 'qrcode';

import { TextBlock } from 'src/components/Misc';
import { UserTwoFAForm, IUserTwoFAForm } from 'src/components/Profile/Forms';
import { useAuth } from 'src/context/auth/use-auth';

type Props = {
  setTOTPEnabled: (isEnabled: boolean) => void;
};

const TOTPModalContainer = ({ setTOTPEnabled }: Props) => {
  const [ui, setUi] = useState({
    isModalVisible: false,
    isLoading: false,
    qrCodeSrc: '',
  });

  const { setUpTOTP, verifyTOTPSetup, updateTOTP } = useAuth();

  const generateQR = async (text: string) => {
    try {
      const url = await QRCode.toDataURL(text);

      setUi((prevUi) => ({ ...prevUi, qrCodeSrc: url }));
    } catch (err) {
      message.error('Something went wrong!');
    }
  };

  const onEnable = async () => {
    setUi((prevUi) => ({ ...prevUi, isLoading: true }));

    try {
      const { href } = await setUpTOTP();

      await generateQR(href);
      setUi((prevUi) => ({ ...prevUi, isModalVisible: true }));
    } catch (e: unknown) {
      message.error('Something went wrong!');
    } finally {
      setUi((prevUi) => ({ ...prevUi, isLoading: false }));
    }
  };

  const onSubmit = async ({ code }: IUserTwoFAForm) => {
    try {
      await verifyTOTPSetup(String(code));
      await updateTOTP(true);

      setUi((prevUi) => ({
        ...prevUi,
        isModalVisible: false,
        qrCodeSrc: '',
      }));
      setTOTPEnabled(true);
    } catch (e: unknown) {
      if (e instanceof Error) {
        message.error(e.message || 'Something went wrong!');
      }
    }
  };

  const onCancel = () => {
    setUi((prevUi) => ({
      ...prevUi,
      isModalVisible: false,
      qrCodeSrc: '',
    }));
  };

  return (
    <Fragment>
      <Button type="primary" onClick={onEnable} loading={ui.isLoading}>
        Enable
      </Button>

      <Modal open={ui.isModalVisible} footer={null} onCancel={onCancel} width={455}>
        <TextBlock
          className="text-center mb-4"
          title="Multi-factor authentication"
          text="To activate MFA, please scan this QR code with your preferred authenticator app. This will connect the Codekeeper app to your account.
          Once you've scanned the QR code, click continue."
        />

        {ui.qrCodeSrc && (
          <div className="text-center">
            <img src={ui.qrCodeSrc} alt="QR Code" />
          </div>
        )}

        <UserTwoFAForm onSubmit={onSubmit} onCancel={onCancel} isLoading={ui.isLoading} />
      </Modal>
    </Fragment>
  );
};

export default TOTPModalContainer;
