import { Outlet, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { ConfigProvider } from 'antd';

import { apolloClient } from 'src/graphql';
import { LayoutContainer as Layout } from 'src/containers/Layout';
import env from 'env';
import Graphiql from 'src/components/GraphiQL/GraphiQL';

import { theme } from '../antd.config.ts';

import { buildProvidersTree } from './utils/buildProvidersTree';
import {
  authRoutes,
  profileRoutes,
  escrowRoutes,
  invitationsRoutes,
  mainRoutes,
  saasEnvironmentsRoute,
  saasEscrowsRoutes,
  backupsRoutes,
} from './routes';
import { AuthProvider } from './context/auth/use-auth';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import { NotFound } from './components/404';
import { ErrorBoundary } from './components/Misc';

import './assets/styles/app.scss';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: (
      <AuthProvider>
        <Outlet />
      </AuthProvider>
    ),
    children: [
      { ...authRoutes },
      { ...invitationsRoutes },
      // Protected routes with main layout
      {
        element: (
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        ),
        errorElement: <ErrorBoundary />,
        children: [
          { ...mainRoutes },
          { ...profileRoutes },
          { ...escrowRoutes },
          { ...saasEnvironmentsRoute },
          { ...saasEscrowsRoutes },
          { ...backupsRoutes },
        ],
      },
      ...(env.VITE_GRAPHIQL_ENABLED
        ? [
            {
              path: 'graphiql',
              element: <Graphiql />,
            },
          ]
        : []),
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: '404',
    element: <NotFound />,
  },
]);

const ProvidersTree = buildProvidersTree([
  [ApolloProvider, { client: apolloClient }],
  [ConfigProvider, { theme }],
  [RouterProvider, { router }],
]);

const App = () => <ProvidersTree />;

export default App;
