import { useState } from 'react';
import classNames from 'classnames';
import { Button, Form, message } from 'antd';

import { countries } from 'src/shared/entities';
import OrganizationForm, { IOrganizationForm } from 'src/components/Account/Organization/OrganizationForm';
import { IProfileQuery, UserRoleEnum } from 'src/graphql/schema';

import OrganizationDetailsInfo, { IOrganizationInfo } from './OrganizationDetailsInfo';

type Props = {
  updateDetails: (data: IOrganizationForm) => Promise<void>;
  company: IOrganizationInfo;
  profile: IProfileQuery['profile'];
};

const OrganizationDetails: React.FunctionComponent<Props> = ({ company, updateDetails, profile }) => {
  const [isEditMode, setEditMode] = useState(false);
  const [form] = Form.useForm();

  if (isEditMode) {
    form.setFieldsValue({
      company: company.companyName || '',
      street: company.street || '',
      number: company.streetNumber || '',
      city: company.city || '',
      zip: company.postalCode || '',
      state: company.region || '',
      country: company.country || '',
      website: company.companyWebsite || '',
      registration: company.companyRegistration || '',
    });
  }

  const onUpdate = async (data: IOrganizationForm) => {
    try {
      await updateDetails(data);
      setEditMode(false);
    } catch (e) {
      message.error('Something went wrong.');
    }
  };

  return (
    <div className={classNames('organisation-details', { '-is-form': isEditMode })}>
      <h2>
        Organization details
        {!isEditMode && (profile.role === UserRoleEnum.Owner || profile.role === UserRoleEnum.Administrator) && (
          <Button onClick={() => setEditMode(true)}>Edit</Button>
        )}
      </h2>

      {isEditMode ? (
        <OrganizationForm
          form={form}
          company={company}
          onUpdate={onUpdate}
          countries={countries}
          toggleEdit={() => setEditMode(false)}
        />
      ) : (
        <OrganizationDetailsInfo company={company} />
      )}
    </div>
  );
};

export default OrganizationDetails;
