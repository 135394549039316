import { useNavigate, useParams } from 'react-router-dom';

import { JourneyModalLayout, JourneyModalHeader } from 'src/components/Journey/ModalLayout';
import { BackupStatusIndicator, BackupStatusIndicatorEnum } from 'src/components/Backup/StatusIndicator';
import { BackupMenu } from 'src/components/Backup/Menu';
import { backupMenuItems } from 'src/components/Backup/Menu/backupMenu.enum';
import { JourneyNavigation } from 'src/components/Journey/Navigation';
import { AlertActionMessage } from 'src/components/Alert';
import { BackupRoutesEnum } from 'src/pages/Backup';

const BackupNewModalLayoutContainer = ({ children }: React.PropsWithChildren) => {
  const navigate = useNavigate();
  const { '*': currentPath = '' } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const integrationId = searchParams.get('i') ?? '';

  const content: ReturnType<typeof getContentOptions>[keyof ReturnType<typeof getContentOptions>] =
    currentPath in getContentOptions(integrationId)
      ? getContentOptions(integrationId)[currentPath as keyof ReturnType<typeof getContentOptions>]
      : getContentOptions()['default'];

  const onClose = () => navigate('/backups');

  const goToPreviousRoute = () => navigate(`/backups/new/platform?i=${integrationId}`);

  const goToNextRoute = () => navigate(`/backups/new/settings?i=${integrationId}`);

  return (
    <JourneyModalLayout
      header={<JourneyModalHeader title="New Backup" onClose={onClose} />}
      statusIndicator={
        <BackupStatusIndicator
          currentStatus={integrationId ? BackupStatusIndicatorEnum.CONFIG : BackupStatusIndicatorEnum.CONNECT}
        />
      }
      menu={<BackupMenu basePath="backups" items={content?.menuItems} backupId="new" />}
    >
      <AlertActionMessage iconType="warn" bgColor="yellow" text={content.alertMessage} />
      {children}
      <JourneyNavigation onPrev={goToPreviousRoute} onNext={goToNextRoute} {...content.navigation} />
    </JourneyModalLayout>
  );
};

export default BackupNewModalLayoutContainer;

function getContentOptions(integrationId?: string) {
  return {
    default: {
      alertMessage: 'Please select your platform.',
      menuItems: backupMenuItems.map((item, index) => (index === 0 ? { ...item, path: '' } : item)),
      navigation: {
        isNextDisabled: true,
        isPrevHidden: true,
      },
    },
    [BackupRoutesEnum.Platform]: {
      alertMessage: 'Please select your platform.',
      menuItems: backupMenuItems.map((item, index) => {
        switch (index) {
          case 0:
            return { ...item, isRequired: !integrationId };
          case 1: {
            if (!integrationId) return item;

            return {
              ...item,
              path: `${item.path}?i=${integrationId}`,
              isRequired: true,
              isDisabled: false,
            };
          }
          default:
            return item;
        }
      }),
      navigation: {
        isNextDisabled: !integrationId,
        isPrevHidden: true,
      },
    },
    [BackupRoutesEnum.Settings]: {
      alertMessage: 'Please configure your backup settings.',
      menuItems: backupMenuItems.map((item, index) => {
        switch (index) {
          case 0:
            return { ...item, path: integrationId ? `${item.path}?i=${integrationId}` : item.path, isRequired: false };
          case 1:
            return { ...item, isRequired: true, isDisabled: false };
          default:
            return item;
        }
      }),
      navigation: {
        isPrevHidden: false,
        isNextHidden: true,
      },
    },
  };
}
