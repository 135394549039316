import { useState, useEffect } from 'react';
import { Button, Table, Input } from 'antd';
import { SearchOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';

import { DepositAddIntegrationLayout } from 'src/components/Deposit/Add';
import { useAddDeposit } from 'src/context/deposit';
import useSorting from 'src/hooks/use-sorting';

import type { TableProps } from 'antd';

import { withInitialOAuthData } from './lib/withInitialOAuthData';

type ITableRow = {
  oauthRepositoryId: string;
  oauthRepositoryFullName: string;
};

type TableRowSelection<T> = TableProps<T>['rowSelection'];

type Props = {
  repositories: ITableRow[];
  isLoading: boolean;
};

const OAuthDepositContainer = ({ repositories, isLoading }: Props) => {
  const [currentRepositories, setCurrentRepositories] = useState<ITableRow[]>([]);

  useEffect(() => {
    if (!isLoading) setCurrentRepositories(repositories);
  }, [repositories, isLoading]);

  const {
    ui: { oauthData, integration, getBack, setOauthData },
  } = useAddDeposit();

  const [selectedRows, setSelectedRows] = useState<ITableRow[]>(oauthData);

  const [order, setOrder] = useSorting();

  const onSelectChange = (_: unknown, selectedRows: ITableRow[]) => setSelectedRows(selectedRows);

  const onSearch = debounce((value: string) => {
    const filteredRepos = repositories.filter(({ oauthRepositoryFullName: name }) =>
      name.toLowerCase().includes(value.toLowerCase()),
    );

    setCurrentRepositories(filteredRepos);
  }, 500);

  const onFilterChange = () => {
    setOrder();
    setCurrentRepositories((repositories) => [...repositories].reverse());
  };

  const rowSelection: TableRowSelection<ITableRow> = {
    selectedRowKeys: selectedRows.map((row) => row.oauthRepositoryId),
    onChange: onSelectChange,
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'oauthRepositoryFullName',
    },
  ];

  const onConfirm = () => setOauthData(selectedRows);

  return (
    <DepositAddIntegrationLayout
      integration={{
        provider: integration?.platform || '',
        account: integration?.name || '',
        repositories: repositories.length,
      }}
      onConfirm={onConfirm}
      isLoading={isLoading}
      confirmButton={{
        disabled: !selectedRows.length,
      }}
      onBack={getBack}
    >
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div style={{ width: 280 }}>
          <Input
            placeholder="Search repositories"
            prefix={<SearchOutlined className="text-light-blue" />}
            onChange={(e) => onSearch(e.target.value)}
            allowClear
          />
        </div>
        <Button onClick={onFilterChange}>
          {order === 'asc' ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
        </Button>
      </div>
      <div className="mb-3">
        <strong>
          <i className="text-light-blue">{selectedRows.length} assets selected</i>
        </strong>
      </div>
      <Table
        className="ck-deposit__assets-table"
        rowHoverable={false}
        columns={columns}
        dataSource={currentRepositories}
        rowSelection={rowSelection}
        pagination={{
          defaultPageSize: 9,
          hideOnSinglePage: true,
        }}
        loading={isLoading}
        rowKey="oauthRepositoryId"
      />
    </DepositAddIntegrationLayout>
  );
};

export default withInitialOAuthData(OAuthDepositContainer);
