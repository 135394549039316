import { capitalize } from 'lodash';

import { IconFolderCheck, IconMultiFolderCheck, IconFolderRemove } from 'src/components/Svg/Icons';

import type { ICustomCheckboxWithIcon } from 'src/components/Form';
import type { EscrowPartyTypeEnum } from 'src/graphql/schema';

export const escrowNotificationsTypeOptions = (partyType: EscrowPartyTypeEnum): ICustomCheckboxWithIcon[] => [
  {
    heading: 'First deposit only',
    text: `${capitalize(partyType)} gets escrow activation confirmation email with deposited asset info.`,
    value: 'first_deposit',
    iconType: 'regular',
    icon: IconFolderCheck,
  },
  {
    heading: 'Each deposit',
    text: `${capitalize(partyType)} gets escrow activation confirmation email with deposited asset info + email sent on each successful deposit.`,
    value: 'each_deposit',
    iconType: 'regular',
    icon: IconMultiFolderCheck,
  },
  {
    heading: 'No notifications',
    text: `${capitalize(partyType)} gets escrow activation confirmation email without deposited asset info.`,
    value: 'no_deposit_notifications',
    iconType: 'regular',
    icon: IconFolderRemove,
  },
];

export const escrowNotificationsDictionary = (
  partyType: EscrowPartyTypeEnum,
): { [key: string]: ICustomCheckboxWithIcon } =>
  escrowNotificationsTypeOptions(partyType).reduce((acc, item) => ({ ...acc, [item.value]: item }), {});

export const disruptedNotificationsOptions = [
  {
    label: 'No notifications',
    value: 'no_distruption_notifications',
  },
  {
    label: 'Immediate Notification',
    value: 'immediate_notification',
  },
];

export const disruptedNotificationsDictionary: { [key: string]: string } = disruptedNotificationsOptions.reduce(
  (acc, item) => ({ ...acc, [item.value]: item.label }),
  {},
);

export const gracePeriodOptions = [
  {
    label: 'No grace period',
    value: 'none',
  },
  {
    label: 'Notification after a grace period of 24 hours',
    value: 'twenty_four_hours',
  },
  {
    label: 'Notification after a grace period of 7 days',
    value: 'seven_days',
  },
  {
    label: 'Notification after a grace period of 30 days',
    value: 'thirty_days',
  },
];

export const gracePeriodDictionary: { [key: string]: string } = gracePeriodOptions.reduce(
  (acc, item) => ({ ...acc, [item.value]: item.label }),
  {},
);

export const storageRegionOptions = [
  {
    value: 'default',
    label: 'Default (Multi-Region)',
  },
  {
    value: 'us-east-1',
    label: 'US East (N. Virginia)',
  },
  {
    value: 'us-east-2',
    label: 'US East (Ohio)',
  },
  {
    value: 'us-west-1',
    label: 'US West (N. California)',
  },
  {
    value: 'us-west-2',
    label: 'US West (Oregon)',
  },
  {
    value: 'af-south-1',
    label: 'Africa (Cape Town)',
  },
  {
    value: 'ap-east-1',
    label: 'Asia Pacific (Hong Kong)',
  },
  {
    value: 'ap-south-1',
    label: 'Asia Pacific (Mumbai)',
  },
  {
    value: 'ap-northeast-3',
    label: 'Asia Pacific (Osaka-Local)',
  },
  {
    value: 'ap-northeast-2',
    label: 'Asia Pacific (Seoul)',
  },
  {
    value: 'ap-southeast-1',
    label: 'Asia Pacific (Singapore)',
  },
  {
    value: 'ap-southeast-2',
    label: 'Asia Pacific (Sydney)',
  },
  {
    value: 'ap-northeast-1',
    label: 'Asia Pacific (Tokyo)',
  },
  {
    value: 'ca-central-1',
    label: 'Canada (Central)',
  },
  {
    value: 'cn-north-1',
    label: 'China (Beijing)',
  },
  {
    value: 'cn-northwest-1',
    label: 'China (Ningxia)',
  },
  {
    value: 'eu-central-1',
    label: 'Europe (Frankfurt)',
  },
  {
    value: 'eu-central-2',
    label: 'Europe (Zurich)',
  },
  {
    value: 'eu-west-1',
    label: 'Europe (Ireland)',
  },
  {
    value: 'eu-west-2',
    label: 'Europe (London)',
  },
  {
    value: 'eu-south-1',
    label: 'Europe (Milan)',
  },
  {
    value: 'eu-west-3',
    label: 'Europe (Paris)',
  },
  {
    value: 'eu-north-1',
    label: 'Europe (Stockholm)',
  },
  {
    value: 'sa-east-1',
    label: 'South America (São Paulo)',
  },
  {
    value: 'me-south-1',
    label: 'Middle East (Bahrain)',
  },
];

export const storageRegionDictionary: { [key: string]: string } = storageRegionOptions.reduce(
  (acc, item) => ({ ...acc, [item.value]: item.label }),
  {},
);
