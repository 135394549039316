import React from 'react';
import classNames from 'classnames';

import styles from './LabelText.module.scss';

export const bgColors = ['orange', 'red', 'purple', 'green', 'light-blue', 'dark-blue', 'gray', 'custom'] as const;

export type Props = {
  text: string;
  icon?: React.MemoExoticComponent<(props: React.SVGProps<SVGSVGElement>) => React.JSX.Element>;
  bgColor: (typeof bgColors)[number];
  className?: string;
};

const LabelText: React.FunctionComponent<Props> = ({ text, icon: Icon, bgColor, className }) => {
  const labelClasses = classNames(styles.label, className, styles[`bg--${bgColor}`]);

  return (
    <span className={labelClasses}>
      {text}
      {Icon && <Icon />}
    </span>
  );
};

export default LabelText;
