import classNames from 'classnames';
import { Fragment } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';

import { renderLogo, logoMap } from 'src/utils/renderLogo';

import styles from './IntegrationRadio.module.scss';

export type IIntegrationItemRadio = {
  id: string;
  platform: string;
  name: string;
  disabled?: boolean;
};

type Props = {
  onClick: (id: string) => void;
  selected: boolean;
} & IIntegrationItemRadio;

const RadioContent = ({ name, platform }: Pick<IIntegrationItemRadio, 'platform' | 'name'>) => {
  const Icon = renderLogo(platform as keyof typeof logoMap);

  return (
    <Fragment>
      <figure className={styles['item-figure']}>
        {Icon && <Icon className={styles['item-logo']} />}
        <figcaption className={styles['item-name']}>{name}</figcaption>
      </figure>
      <div className={styles['item-status']}>
        <CheckCircleFilled className={styles['check-icon']} />
        <span className={styles['item-status__text']}>Activated</span>
      </div>
    </Fragment>
  );
};

const IntegrationItemRadio = ({ id, platform, name, onClick, selected, disabled = false }: Props) => {
  const handleClick = () => onClick(id);
  const itemClasses = classNames(styles.item, {
    [styles.selected]: selected,
    [styles.disabled]: disabled,
  });

  if (disabled) {
    return (
      <Tooltip placement="top" title="You don't have the required permissions to back up this integration's assets.">
        <div className={itemClasses}>
          <div className={styles.radio} />
          <RadioContent platform={platform} name={name} />
        </div>
      </Tooltip>
    );
  }

  return (
    <div className={itemClasses} onClick={handleClick}>
      <div className={styles.radio}>{selected && <CheckCircleFilled className={styles['check-icon']} />}</div>

      <RadioContent platform={platform} name={name} />
    </div>
  );
};

export default IntegrationItemRadio;
