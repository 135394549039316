import { useState } from 'react';
import { Modal, message } from 'antd';

import { useAuth } from 'src/context/auth/use-auth';
import { useConfirmEmailChangeMutation, ProfileDocument } from 'src/graphql/schema';
import {
  UserConfirmationCodeForm,
  UserChangeEmailForm,
  IUserConfirmationCodeForm,
  IUserChangeEmailForm,
} from 'src/components/Profile/Forms';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  email: string;
};

const UserChangeEmailContainer = ({ isOpen, setIsOpen, email }: Props) => {
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [newEmail, setNewEmail] = useState('');

  const { changeEmail } = useAuth();
  const [confirmEmailChange, { loading: loadingConfirmation }] = useConfirmEmailChangeMutation({
    refetchQueries: [ProfileDocument],
  });

  const onSubmitEmail = async ({ email }: IUserChangeEmailForm) => {
    try {
      await changeEmail(email);
      setIsCodeSent(true);
      setNewEmail(email);
    } catch (e: unknown) {
      if (e instanceof Error) message.error(e.message);
    }
  };

  const onSubmitCode = async ({ code }: IUserConfirmationCodeForm) => {
    try {
      const { data } = await confirmEmailChange({ variables: { code } });

      if (data?.confirmEmailChange?.success) {
        setIsCodeSent(false);
        setNewEmail('');
        setIsOpen(false);
        message.success('Email changed!');
      } else {
        throw new Error(data?.confirmEmailChange?.errors?.[0]);
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error = e as Error & { code: string };

        if (error.code === 'CodeMismatchException' || error.code === 'InvalidParameterException') {
          message.error('Invalid confirmation code, please check your email again!');
        } else {
          message.error(e.message || 'Something went wrong!');
        }
      }
    }
  };

  const onCancel = () => {
    setIsOpen(false);
    setIsCodeSent(false);
    setNewEmail('');
  };

  return (
    <Modal open={isOpen} title="Change email" onCancel={onCancel} footer={null}>
      {isCodeSent ? (
        <UserConfirmationCodeForm
          email={newEmail}
          onSubmit={onSubmitCode}
          onCancel={onCancel}
          isLoading={loadingConfirmation}
        />
      ) : (
        <UserChangeEmailForm email={email} onSubmit={onSubmitEmail} onCancel={onCancel} />
      )}
    </Modal>
  );
};

export default UserChangeEmailContainer;
