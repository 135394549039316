import { Fragment, useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import { useAddUserMutation } from 'src/graphql/schema/index.ts';

import { StepsEnum } from '../../InviteSteps.enum';

import TeamMember from './components/TeamMember';

type InvitedUser = {
  name: string;
  email: string;
};

export default function TeamMemberForm({ setStep }: { setStep: (step: StepsEnum) => void }) {
  const [form] = Form.useForm();
  const [addMember] = useAddUserMutation();
  const [invitedUsers, setInvitedUsers] = useState<InvitedUser[]>([]);
  const [isFormVisible, setFormVisibility] = useState(true);

  const onFinish = (values: { name: string; email: string }) => {
    setInvitedUsers([...invitedUsers, { name: values.name, email: values.email }]);
    setFormVisibility(false);

    form.setFieldsValue({
      name: '',
      email: '',
    });
    message.success('Team member added!');
  };

  const removeUser = (email: string) => {
    const filteredUsers: InvitedUser[] = invitedUsers.filter((item) => item.email !== email);

    setInvitedUsers(filteredUsers);

    message.success('Team member removed!');
  };

  const inviteUsers = async () => {
    try {
      const data = await addMember({
        variables: {
          users: invitedUsers,
        },
      });

      if (data?.data?.addUser?.success) {
        message.success('Invitations sent!');
        setStep('invitations');
      } else {
        message.error('Something went wrong!');
      }
    } catch (e) {
      message.error('Something went wrong');
      console.log(e);
    }
  };

  return (
    <div className="w-100">
      <h2 className="invite-screen__title">Invite team member</h2>
      <p className="invite-screen__subtitle">Invite new user to your organization</p>

      {invitedUsers.map((item, index) => (
        <Fragment key={index}>
          <TeamMember removeUser={removeUser} item={item} index={index} />
        </Fragment>
      ))}
      {isFormVisible && (
        <Form layout="vertical" name="basic" onFinish={onFinish} form={form}>
          <Form.Item
            label="Full name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please add the full name',
              },
            ]}
          >
            <Input placeholder="eg. Alex Smith" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'Invalid email address',
              },
              {
                required: true,
                message: 'Please add an email address',
              },
            ]}
          >
            <Input placeholder="example@email.com" />
          </Form.Item>

          <Form.Item shouldUpdate>
            {() => (
              <Button
                disabled={form.isFieldsTouched(true) ? form.getFieldsError().some(({ errors }) => errors.length) : true}
                htmlType="submit"
                className="invite-screen__btn -save"
              >
                Save User
              </Button>
            )}
          </Form.Item>
        </Form>
      )}

      {!isFormVisible && (
        <p className="invite-screen__add" onClick={() => setFormVisibility(true)}>
          + Add another user
        </p>
      )}

      <Form.Item>
        <Button
          type="primary"
          className="invite-screen__account -invite-btn mt-0"
          onClick={inviteUsers}
          disabled={!invitedUsers.length}
        >
          Invite & Next
        </Button>
      </Form.Item>

      <Button
        type="link"
        className="mt-4 d-flex align-items-center justify-content-center w-100 invite-screen__skip"
        onClick={() => setStep('invitations')}
      >
        Skip invite member <RightOutlined />
      </Button>
    </div>
  );
}
