import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

import { JourneyModalLayout } from 'src/components/Journey/ModalLayout';
import { useBackup } from 'src/context/backup';
import { BackupStatusEnum } from 'src/graphql/schema';

import StartBackupContainer from './Actions/StartBackupContainer';

const BackupModalFooter = JourneyModalLayout.Footer;

const actionItemsKeys = ['start-backup', 'close', 'close-access'] as const;

const BackupModalFooterContainer = () => {
  const navigate = useNavigate();
  const { backup, ui, setEditAccessMode } = useBackup();

  let actionItems: { key: (typeof actionItemsKeys)[number]; component: React.ReactNode }[] = [
    {
      key: 'close',
      component: (
        <Button type="default" onClick={() => navigate('/backups')}>
          Close
        </Button>
      ),
    },
  ];

  if (ui.isEditSettingsMode) return null;

  if (ui.isEditAccessMode) {
    const handleCancel = () => setEditAccessMode(false);

    actionItems = [
      {
        key: 'close-access',
        component: <Button onClick={handleCancel}>Close</Button>,
      },
    ];
  }

  if (backup.status === BackupStatusEnum.Draft && !ui.isEditAccessMode)
    actionItems = [
      ...actionItems.filter(({ key }) => key !== 'close'),
      {
        key: 'start-backup',
        component: <StartBackupContainer />,
      },
    ];

  return (
    <BackupModalFooter justify="end">
      {actionItems.map(({ key, component }) => (
        <BackupModalFooter.Item key={key}>{component}</BackupModalFooter.Item>
      ))}
    </BackupModalFooter>
  );
};

export default BackupModalFooterContainer;
