import { useNavigate } from 'react-router-dom';

import { JourneyModalLayout } from 'src/components/Journey/ModalLayout';
import { useBackup } from 'src/context/backup';
import { integrationLabelStatusEnum as statusTypeEnum } from 'src/components/Backup/backup.enum';

const BackupModalHeaderContainer = () => {
  const navigate = useNavigate();
  const { backup } = useBackup();

  const onClose = () => navigate('/backups');

  const statusType: (typeof statusTypeEnum)[keyof typeof statusTypeEnum] | undefined =
    backup.status in statusTypeEnum ? statusTypeEnum[backup.status] : undefined;

  return (
    <JourneyModalLayout.Header
      title={backup?.integration.accountName ?? ''}
      onClose={onClose}
      statusLabel={statusType}
    />
  );
};

export default BackupModalHeaderContainer;
