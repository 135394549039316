import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import classNames from 'classnames';

import { ImageConnectPlatform, ImageEmptyBox } from 'src/components/Svg/Images';

import styles from './BackupEmpty.module.scss';

type Props = {
  type: 'connect' | 'new';
  className?: string;
};

const backupContentOptions = {
  connect: {
    icon: ImageConnectPlatform,
    heading: 'Connect your platform in\n Integrations page',
    text: 'Secure your data now! Click the button below to start a new backup.',
    linkText: 'Open Integrations Page',
    to: '/integrations',
  },
  new: {
    icon: ImageEmptyBox,
    heading: 'Start your backup',
    text: 'Secure your data now! Click the button below to start a new backup.',
    linkText: '+ New Backup',
    to: '/backups/new',
  },
};

const BackupEmpty = ({ type, className }: Props) => {
  const navigate = useNavigate();
  const blockClasses = classNames(styles.block, className);

  const content = backupContentOptions[type];
  const Icon = content.icon;

  return (
    <div className={blockClasses}>
      <Icon className={`${styles.icon} ${styles[type]}`} />
      <div className={styles.heading}>{content.heading}</div>
      <p className={styles.text}>{content.text}</p>
      <Button type="primary" onClick={() => navigate(content.to)} size="large">
        {content.linkText}
      </Button>
    </div>
  );
};

export default BackupEmpty;
