import { Button, message } from 'antd';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useActivateEscrowMutation } from 'src/graphql/schema';
import { ModalConfirmation, ModalProceed } from 'src/components/Misc';
import { useEscrow } from 'src/context/escrow';

type Props = {
  escrowId: string;
  isActivatable?: boolean;
  isPendingActivation?: boolean;
};

const ActivateEscrowActionContainer = ({ escrowId, isActivatable = false, isPendingActivation = false }: Props) => {
  const [ui, setUi] = useState({
    isConfirmModalOpen: false,
    isProceedModalOpen: false,
    isLoading: false,
  });
  const { refetch } = useEscrow();
  const [activateEscrow] = useActivateEscrowMutation();
  const navigate = useNavigate();

  const onActivateEscrow = async () => {
    setUi((prevUi) => ({ ...prevUi, isLoading: true }));

    try {
      const { data } = await activateEscrow({
        variables: {
          escrowId,
        },
      });

      if (data?.activateEscrow?.success) {
        setUi((prevUi) => ({ ...prevUi, isProceedModalOpen: false, isConfirmModalOpen: true }));
      } else {
        throw Error(data?.activateEscrow?.errors?.[0]);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        message.error(error.message || 'Something went wrong!');
      }
    } finally {
      setUi((prevUi) => ({ ...prevUi, isLoading: false }));
    }
  };

  const onCloseConfirmation = async () => {
    await refetch();
    setUi((prevUi) => ({ ...prevUi, isConfirmModalOpen: false }));
    navigate('/');
  };

  return (
    <Fragment>
      <Button
        type="primary"
        disabled={!isActivatable || isPendingActivation}
        onClick={() => setUi((prevUi) => ({ ...prevUi, isProceedModalOpen: true }))}
      >
        Activate Escrow
      </Button>

      <ModalProceed
        title="Are you sure you want to activate this escrow?"
        text="By activating the escrow, you confirm the deposits are in order."
        isOpen={ui.isProceedModalOpen}
        proceedText="Activate"
        onCancel={() => setUi((prevUi) => ({ ...prevUi, isProceedModalOpen: false }))}
        onProceed={onActivateEscrow}
        isLoading={ui.isLoading}
      />

      <ModalConfirmation
        isOpen={ui.isConfirmModalOpen}
        title="Escrow activation in progress!"
        text="Your assets have now successfully been connected to this escrow! Please note that larger deposits may take a moment to sync."
        onClose={onCloseConfirmation}
        okText="Back to Dashboard"
      />
    </Fragment>
  );
};

export default ActivateEscrowActionContainer;
