import { useState } from 'react';
import * as Sentry from '@sentry/react';
import { Upload, message } from 'antd';
import { InboxOutlined, LockOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib';

import useSecureUpload from 'src/hooks/use-secure-upload';
import { DepositAssetProgressModal } from 'src/components/Deposit';
import FormFooter from 'src/components/Deposit/FormFooter/FormFooter';
import { UPLOAD_SIZE_LIMIT } from 'src/shared/constants';
import { formatBytes } from 'src/utils/formatBytes';

import styles from './Uploader.module.scss';

const { Dragger } = Upload;

type Props = {
  escrowId: string;
  resetModal: () => void;
  isUploading: boolean;
  setIsUploading: (isUploading: boolean) => void;
  back?: () => void;
};

export function Uploader({ escrowId, resetModal, isUploading, setIsUploading, back }: Props) {
  const [files, setFiles] = useState<Array<UploadFile & { uid: string }>>([]);

  const formattedSizeLimit = formatBytes(UPLOAD_SIZE_LIMIT, 0);
  const properties = {
    name: 'file',
    multiple: true,
    fileList: files,
    onRemove: (file: UploadFile) => {
      setFiles(files.filter((f) => f.uid !== file.uid));
    },
    beforeUpload: (file: UploadFile) => {
      if (!file.type) {
        message.error('File type is not supported.');
        return false;
      }
      if (typeof file.size === 'number' && file.size > UPLOAD_SIZE_LIMIT) {
        file.status = 'error';
        file.response = `File size exceeds the limit of ${formattedSizeLimit}. Please upload a smaller file.`;
      }
      setFiles((files: UploadFile[]) => [...files, file].slice(0, 5));
      return false;
    },
    files,
  };
  const [uploadFiles, cancelUpload, , uploadCompleted, uploadProgress] = useSecureUpload(escrowId);
  const filteredList = files.filter((file) => file.status !== 'error');
  const uploadSelectedFiles = async () => {
    if (isUploading) return;

    setIsUploading(true);

    try {
      await uploadFiles(filteredList);
      setIsUploading(false);
      resetModal();
      setFiles([]);
    } catch (e) {
      setIsUploading(false);
      if ((e as Error).name === 'CanceledError') {
        Sentry.captureException(e);
        message.error('Upload canceled');
      } else {
        throw e;
      }
    }
  };
  const onDiscard = () => {
    setIsUploading(false);
    cancelUpload();
  };

  return (
    <div className="ck-deposit-assets__form">
      <Dragger {...properties} className={styles.upload}>
        <div className={styles['upload-wrapper']}>
          <InboxOutlined className={styles['upload-icon']} />
          <p className={styles['upload-text']}>
            Drag and drop your files here
            <br />
            <span>You can add a number of files.</span> <br />
            or
            <strong>Browse files</strong>
          </p>
        </div>

        <div className={`d-flex justify-content-between ${styles['upload-footer']}`}>
          <strong>File size limit: {formattedSizeLimit}</strong>
          <span className={styles['secured-label']}>
            <LockOutlined className={styles['secured-icon']} /> Secured
          </span>
        </div>
      </Dragger>
      <DepositAssetProgressModal
        isOpen={isUploading}
        total={files.length}
        percent={uploadProgress.percent}
        currentCount={uploadProgress.currentCount}
        onDiscard={onDiscard}
      />
      <FormFooter
        cancel={() => {
          resetModal();
          setFiles([]);
        }}
        confirm={uploadSelectedFiles}
        disableConfirm={!filteredList.length || isUploading}
        hideConfirm={isUploading && !uploadCompleted}
        hideCancel={isUploading && uploadCompleted}
        isLoading={isUploading}
        back={back}
      />
    </div>
  );
}
