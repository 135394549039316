import { useNavigate } from 'react-router-dom';

import { useSaasEscrow } from 'src/context/saas';
import { JourneyModalLayout } from 'src/components/Journey/ModalLayout';
import { labelStatusTypeEnum } from 'src/components/Saas/Escrows';

const SaasModalHeaderContainer = () => {
  const navigate = useNavigate();
  const { environment } = useSaasEscrow();

  const onClose = () => navigate('/saas-escrows');

  let statusLabel: (typeof labelStatusTypeEnum)[keyof typeof labelStatusTypeEnum] | undefined =
    environment.status in labelStatusTypeEnum
      ? labelStatusTypeEnum[environment.status as keyof typeof labelStatusTypeEnum]
      : undefined;

  if (environment.saasEnvironmentTermination) statusLabel = labelStatusTypeEnum['terminated-requested'];

  return <JourneyModalLayout.Header title={environment?.name} statusLabel={statusLabel} onClose={onClose} />;
};

export default SaasModalHeaderContainer;
