import { Fragment } from 'react';
import { Button, message } from 'antd';

import { TextBlock } from 'src/components/Misc';
import { useCreateCustomAssetsMutation } from 'src/graphql/schema';
import { useAddDeposit } from 'src/context/deposit';
import { ClientError, getApiError } from 'src/utils/errors';

const CustomDepositConfirmContainer = () => {
  const [createCustomAssets, { loading }] = useCreateCustomAssetsMutation();
  const {
    ui: { integration, selectedEscrowId, customData: assetsInput, setInitialData, setStep, getBack },
  } = useAddDeposit();

  const onConfirm = async () => {
    try {
      const { data } = await createCustomAssets({
        variables: {
          escrowId: selectedEscrowId,
          integrationId: integration?.id || '',
          assetsInput,
        },
      });

      if (data?.createCustomAssets?.success) {
        setStep('success');
      } else {
        throw new ClientError(data?.createCustomAssets?.errors?.[0] ?? 'An error occurred');
      }
    } catch (e) {
      getApiError(e, message.error);
    }
  };

  return (
    <Fragment>
      <TextBlock
        title={`Are you sure you want to connect these\nassets to your escrow?`}
        text="These assets will be deposited into our secure vaults."
        className="mb-4"
        classes={{
          title: 'text-lg',
        }}
      />

      <div className="d-flex justify-content-between">
        <Button type="text" onClick={getBack}>
          Back
        </Button>
        <div className="d-flex justify-content-end gap-12 flex-grow-1">
          <Button onClick={setInitialData}>Cancel</Button>
          <Button type="primary" onClick={onConfirm} loading={loading}>
            Confirm
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomDepositConfirmContainer;
