import { Form, Select, Row } from 'antd';
import { forwardRef, useEffect } from 'react';

import { TextBlock } from 'src/components/Misc';
import { DisruptionNotificationEnum, DisruptionNotificationGracePeriodEnum } from 'src/graphql/schema';

import { versionsOptions, disruptedNotificationsOptions, gracePeriodOptions } from '../../backup.enum';

import type { FormInstance, FormProps } from 'antd/lib/form';

import { setGracePeriodOption, settingsFieldsChange } from './lib';
import styles from './BackupSettingsForm.module.scss';

export type IEditBackupSettingsForm = {
  versionsLimit: string;
  disruptionNotification: DisruptionNotificationEnum;
  disruptionNotificationGracePeriod?: DisruptionNotificationGracePeriodEnum | 'none';
};

type Props = {
  onSubmit: (data: IEditBackupSettingsForm) => void;
  initialValues?: IEditBackupSettingsForm;
};

type Ref = FormInstance;

const EditBackupSettingsForm = forwardRef<Ref, Props>(({ onSubmit, initialValues }, ref) => {
  const [form] = Form.useForm<IEditBackupSettingsForm>();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        versionsLimit: initialValues.versionsLimit,
        disruptionNotification: initialValues.disruptionNotification,
        disruptionNotificationGracePeriod: setGracePeriodOption(
          initialValues.disruptionNotification,
          initialValues.disruptionNotificationGracePeriod as DisruptionNotificationGracePeriodEnum,
        ),
      });
    }
  }, [form, initialValues]);

  const currentDisruptionNotification = Form.useWatch('disruptionNotification', form) as DisruptionNotificationEnum;
  const isNoDisruptionNotifications =
    currentDisruptionNotification === DisruptionNotificationEnum.NoDistruptionNotifications;

  const onFieldsChange: FormProps['onFieldsChange'] = (_, allFields) => settingsFieldsChange(form)(_, allFields);

  return (
    <Form form={form} onFinish={onSubmit} onFieldsChange={onFieldsChange} ref={ref} role="form">
      <div className={styles.block}>
        <TextBlock
          as="h4"
          titleColor="dark-blue"
          title="Backup Version History"
          text="Keep a version history of your backups."
          className="mb-3"
        />
        <Form.Item
          name="versionsLimit"
          className={styles.select}
          rules={[
            {
              required: true,
              message: 'Version is required',
            },
          ]}
        >
          <Select placeholder="Select version" options={versionsOptions} />
        </Form.Item>
      </div>

      <div className={styles.block}>
        <TextBlock
          as="h4"
          titleColor="dark-blue"
          title="Disrupted Connection Notifications"
          text="Set up alerts if your backup process is interrupted."
          className="mb-3"
        />

        <div className={styles['container--594']}>
          <Row gutter={[0, 0]} wrap={false}>
            <Form.Item
              name="disruptionNotification"
              className={styles.select}
              rules={[
                {
                  required: true,
                  message: 'Notification is required',
                },
              ]}
            >
              <Select placeholder="Select notification" options={disruptedNotificationsOptions} />
            </Form.Item>
            <Form.Item name="disruptionNotificationGracePeriod" className={styles['select--320']}>
              <Select
                disabled={isNoDisruptionNotifications}
                placeholder="Select grace period"
                options={gracePeriodOptions}
              />
            </Form.Item>
          </Row>
        </div>
      </div>
    </Form>
  );
});

EditBackupSettingsForm.displayName = 'EditBackupSettingsForm';

export default EditBackupSettingsForm;
