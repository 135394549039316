import { message, Skeleton } from 'antd';
import { useReadQuery } from '@apollo/client';

import { BillingPayment } from 'src/components/Billing';
import { useUpdatePaymentMethodMutation, CompanyDocument } from 'src/graphql/schema';
import { withSuspense } from 'src/hoc';

import type { ICompanyQuery, IBillingQuery } from 'src/graphql/schema';
import type { QueryRef } from '@apollo/client';

type Props = {
  queryRef: QueryRef<ICompanyQuery>;
};

const BillingPaymentContainer = ({ queryRef }: Props) => {
  const [updatePaymentMethod] = useUpdatePaymentMethodMutation({
    refetchQueries: [CompanyDocument],
  });
  const { data } = useReadQuery<IBillingQuery>(queryRef);

  const paymentMethod = data.billing?.paymentMethod;

  const updatePayment = async () => {
    try {
      const { data } = await updatePaymentMethod();

      if (data?.updatePaymentMethod?.success) {
        window.location.href = data.updatePaymentMethod.url || '';
      } else {
        throw new Error(data?.updatePaymentMethod?.errors?.[0]);
      }
    } catch (error: unknown) {
      if (error instanceof Error) message.error(error.message);
    }
  };

  return <BillingPayment onUpdate={updatePayment} paymentMethod={paymentMethod} />;
};

const PaymentSkeleton = () => (
  <Skeleton
    active
    paragraph={{
      rows: 1,
    }}
  />
);

export default withSuspense(BillingPaymentContainer, PaymentSkeleton);
