import { Fragment, useState } from 'react';
import { Button, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { BackupMemberRoleEnum } from 'src/graphql/schema';

import BackupResultItemCard from './BackupResultItemCard';
import BackupResultItemContent, { IBackupResultItemData } from './BackupResultItemContent';
import { shouldHideDownloadAction } from './lib/shouldHideDownloadAction';
import styles from './BackupResult.module.scss';

type Props = {
  data: IBackupResultItemData & { lastBackupVersionId: string; children: IBackupResultItemData[] };
  currentUserRole: BackupMemberRoleEnum;
  className?: string;
  onDownload: (id: string) => Promise<void>;
  onShowHistory: () => void;
  isLoading?: boolean;
};

const BackupResultItemWithHistory = ({
  data,
  currentUserRole,
  className,
  onDownload,
  onShowHistory,
  isLoading = false,
}: Props) => {
  const [showHistory, setShowHistory] = useState(false);
  const [downloadId, setDownloadId] = useState<string | null>(null);

  const toggleHistory = () => {
    setShowHistory((prevHistory) => !prevHistory);

    if (!showHistory) onShowHistory();
  };

  const handleDownload = (id: string) => async () => {
    setDownloadId(id);

    try {
      await onDownload(id);
    } finally {
      setDownloadId(null);
    }
  };

  return (
    <BackupResultItemCard className={className}>
      <BackupResultItemContent
        data={{
          id: data.id,
          name: data.name,
          version: data.version,
          date: data.date,
          type: data.type,
          text: data.text,
        }}
        actions={
          <Fragment>
            {shouldHideDownloadAction(currentUserRole, data.type, data.lastBackup) ? null : (
              <div className={styles['item-action']}>
                <Button
                  ghost
                  type="primary"
                  onClick={handleDownload(data.lastBackupVersionId)}
                  loading={downloadId === data.id}
                >
                  Request Download
                </Button>
              </div>
            )}
            <div className={`${styles['item-action']} ${styles.show}`}>
              <Button type="text" onClick={toggleHistory} disabled={isLoading}>
                Show version history <DownOutlined rotate={showHistory ? 180 : 0} className={styles['icon-arrow']} />
              </Button>
            </div>
          </Fragment>
        }
      />

      {showHistory && (
        <div className={styles['version-list']}>
          {isLoading ? (
            <BackupResultItemContent.Skeleton />
          ) : (
            <Fragment>
              {data.children.length ? (
                data.children.map((item) => (
                  <BackupResultItemContent
                    key={item.id}
                    data={item}
                    className={styles['version-item']}
                    actions={
                      shouldHideDownloadAction(currentUserRole, item.type) ? null : (
                        <Button ghost type="primary" onClick={handleDownload(item.id)} loading={downloadId === item.id}>
                          Request Download
                        </Button>
                      )
                    }
                  />
                ))
              ) : (
                <Typography.Title type="secondary" level={4}>
                  No versions available.
                </Typography.Title>
              )}
            </Fragment>
          )}
        </div>
      )}
    </BackupResultItemCard>
  );
};

export default BackupResultItemWithHistory;
